import { Box,} from "@mui/material";
import React from "react";

import EastArrow from "../../../assests/svg/Common/EastArrow/index";

function FarwarButton({
  btnBgColor='rgba(141, 171, 225, 0.26)',
  btnHeigth='30px',
  btnWidth='42px',
  iconColor='black',
  className="",
  icon=<EastArrow svgcolor={iconColor} />,
  onBtnClick=()=>{}
}) {
  return (
    <Box
      height={btnHeigth}
      width={btnWidth}
      borderRadius={"15px"}
      bgcolor={btnBgColor}
      className={`d-flex justify-content-center align-items-center ${className}`}
      onClick={onBtnClick}
      sx={{cursor:"pointer"}}
    >
      {icon}
    </Box>
  );
}

export default FarwarButton;
