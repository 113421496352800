import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import CVfrom from "../../../molecules/CVfrom";
import { useToasts } from "react-toast-notifications";
import { postFormData } from "../../../../services/contactus";
import InputBoxComponent from "../../../atoms/InputBoxComponent";

function ContactUsFormLayout() {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    mobile: "",
    msg: "",
  });
  const { addToast } = useToasts();

  let handelChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const [fNameError, setfNameError] = useState("");
  const [lastNameError, setlastNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [meaasageError, setMeaasageError] = useState("");

  let { fname, lname, email, mobile, msg } = formData;

  let validateFName = () => {
    if (fname) {
      let regex = /^[A-Za-z\s]+$/;
      if (regex.test(fname)) {
        setfNameError("");
        return true;
      } else {
        setfNameError("Invalid First Name");
      }
    } else {
      setfNameError("Required First Name");
    }
  };

  let validateLName = () => {
    if (lname) {
      let regex = /^[A-Za-z\s]+$/;
      if (regex.test(lname)) {
        setlastNameError("");
        return true;
      } else {
        setlastNameError("Invalid Last Name");
      }
    } else {
      setlastNameError("Required Last Name");
    }
  };

  let validateNumber = () => {
    if (mobile) {
      let regex = /^\d{10}$/;
      if (regex.test(mobile)) {
        setNumberError("");
        return true;
      } else {
        setNumberError("Invalid Phone Number");
      }
    } else {
      setNumberError("Required Phone Number");
    }
  };

  let validateEamil = () => {
    if (email) {
      let regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+(\.\w{2,3})$/;
      if (regex.test(email)) {
        setEmailError("");
        return true;
      } else {
        setEmailError("Invalid Email");
      }
    } else {
      setEmailError("Required Email");
    }
  };

  let validateMeaasage = () => {
    if (msg) {
      let regex = /^[A-Za-z\s]{1,200}$/;
      if (regex.test(msg)) {
        setMeaasageError("");
        return true;
      } else {
        setMeaasageError("accept alphabets only (1-200)");
      }
    } else {
      setMeaasageError("Required Message");
    }
  };

  let handleValidate = () => {
    validateFName();
    validateLName();
    validateNumber();
    validateEamil();
    validateMeaasage();
    if (
      validateFName() &&
      validateLName() &&
      validateNumber() &&
      validateEamil() &&
      validateMeaasage()
    ) {
      submitFromData();
    }
  };

  const submitFromData = async () => {
    let { data, errRes } = await postFormData(formData);
    if (data) {
      if (data.error) {
        addToast(data.message, { appearance: "error" });
      } else {
        addToast(data.message, { appearance: "success" });
        setFormData({
          ...formData,
          fname: "",
          lname: "",
          email: "",
          mobile: "",
          msg: "",
        });
      }
    } else if (errRes) {
      addToast(errRes.message, { appearance: "error" });
    }
  };

  const inputsFields = [
    {
      filed: (
        <InputBoxComponent
          placeholder="Enter First Name"
          textLabel="First Name"
          name="fname"
          value={formData.fname}
          required={true}
          autoComplete="off"
          onChange={handelChange}
          onBlur={validateFName}
          fullWidth={true}
          errorText={fNameError}
        />
      ),
    },
    {
      filed: (
        <InputBoxComponent
          placeholder="Enter Last Name"
          textLabel="Last Name"
          name="lname"
          autoComplete="off"
          value={formData.lname}
          required={true}
          onChange={handelChange}
          onBlur={validateLName}
          fullWidth={true}
          errorText={lastNameError}
        />
      ),
    },
    {
      filed: (
        <InputBoxComponent
          placeholder="Enter Mobile Number"
          textLabel="Mobile Number"
          name="mobile"
          autoComplete="off"
          value={formData.mobile}
          onBlur={validateNumber}
          required={true}
          onChange={handelChange}
          fullWidth={true}
          errorText={numberError}
        />
      ),
    },
    {
      filed: (
        <InputBoxComponent
          placeholder="Enter Email ID"
          textLabel="Email ID"
          name="email"
          autoComplete="off"
          value={formData.email}
          onBlur={validateEamil}
          required={true}
          onChange={handelChange}
          fullWidth={true}
          errorText={emailError}
        />
      ),
    },
  ];

  let extrainput = (
    <InputBoxComponent
      placeholder="Enter Message..."
      textLabel="Message"
      name="msg"
      autoComplete="off"
      value={formData.msg}
      onBlur={validateMeaasage}
      onChange={handelChange}
      required={true}
      fullWidth={true}
      errorText={meaasageError}
    />
  );

  return (
    <>
      <Grid
        item
        container
        className="mt-5"
        paddingBottom={2}
        position={"relative"}
      >
        <Grid item xs={1} md={1}></Grid>
        <Grid item container xs={10} md={10} height={"70%"}>
          <Grid
            item
            xs={12}
            className="p-3 my-auto  rounded-1 z-3"
            bgcolor={"white"}
          >
            <Paper elevation={3}>
              <CVfrom
                showcloseIcon={false}
                inputsFields={inputsFields}
                message={extrainput}
                heading="Have any question? Feel free to"
                subheading="Contact us"
                onSubmit={handleValidate}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={1} md={1}></Grid>
      </Grid>
    </>
  );
}

export default ContactUsFormLayout;
