import { Box } from "@mui/material";

function RoundedRectangleBox({
  height = "",
  top = "",
  left = "",
  width = "",
  sx = {},
  backgroundColor = "#24ABE3",
  className = "",
  animationClassName = "",
  zIndex = 2,
}) {
  return (
    <Box
      className={className}
      sx={{
        position: "absolute",
        zIndex: { zIndex },
        height: { height },
        top: { top },
        left: { left },
        width: { width },
        ...sx,
      }}
    >
      <Box
        className={animationClassName}
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: backgroundColor,
          borderRadius: "999px",
          opacity: "50%",
        }}
      >
        &nbsp;
      </Box>
    </Box>
  );
}

export default RoundedRectangleBox;
