import { Grid, Typography } from "@mui/material";
import React from "react";
import ChipComponent from "../../atoms/ChipComponent";
import { useNavigate } from "react-router-dom";
const S3_URL = process.env.REACT_APP_S3_URL;
const blog1 = S3_URL + "/v3/assestsWebp/home/blogImage1.webp";
const clock = S3_URL + "/v3/assestsWebp/home/blogClockIcon.webp";

function HomepageBlogsCard({
  imageUrl = blog1,
  label = "FINANCE",
  title = "",
  muicardprops = "",
}) {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        width={"95%"}
        className={` image-container card-container ${muicardprops}`}
        sx={{ cursor: "pointer" }}
        onClick={() => navigate("/resources/blogs")}
      >
        <Grid item xs={12} className="image-container overflow-hidden">
          <img loading="lazy"  src={imageUrl} alt="blog1" className="w-100 " />
          <div className="overlay d-flex justify-content-center align-items-center"></div>
        </Grid>

        <Grid
          item
          container
          xs={12}
          padding={"5%"}
          className="mb-2 card-border"
          borderBottom={""}
          backgroundColor={"white"}
        >
          <Grid item xs={12} paddingTop={"5%"}>
            <ChipComponent
              label={label}
              variant={"text"}
              sx={{
                backgroundColor: "rgba(141, 171, 225, 0.26)",
                color: "rgba(36, 171, 227, 1)",
              }}
              chipMuiProps="content-chip1"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              className="fw-700 fs-14 container-text card-heading"
              sx={{
                height: { sm: "15vh", md: "14vh", lg: "12vh", xl: "10vh" },
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            paddingY={"5%"}
            borderTop={"1px solid #81808C"}
            className="d-flex pb-2 "
          >
            <img loading="lazy"  src={clock} alt="" height={"85%"} /> &nbsp; &nbsp;
            <Typography className="fs-10 txt-description">
              20 Mar, 2024{" "}
            </Typography>{" "}
            &nbsp; &nbsp;
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default HomepageBlogsCard;
