import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ProductsCard from "../ProductsCard";

function ServicesConsultingCards() {
  const ServicesData = [
    {
      title1: "Permanent",
      title2: "Hire",
      cardcolor: "rgba(36, 171, 227, 0.6)",
      link:"/permanent-hire"
    },
    {
      title1: "Contract",
      title2: "Staffing",

      cardcolor: "rgba(25, 24, 37, 0.7)",
      link:"/contract-staffing"
    },
    {
      title1: "Contract to",
      title2: "Hire Staffing",
      cardcolor: "rgba(36, 171, 227, 0.6)",
      link:"/contract-to-hire-staffing"
    },
    {
      title1: "Build Operate",
      title2: "Transfer",
      cardcolor: "rgba(25, 24, 37, 0.7)",
      link:"/build-operate-transfer"
    },
    {
      title1: "Forecast Based",
      title2: "Hire",
      cardcolor: "rgba(25, 24, 37, 0.7)",
      link:"/forecast-based-hire"
    },
    
    {
      title1: "Just in",
      title2: "Time Hire",
      cardcolor: "rgba(36, 171, 227, 0.6)",
      link:"/just-in-time-hire"
    },
    {
      title1: "Deploy Observe",
      title2: "Bill",
      cardcolor: "rgba(25, 24, 37, 0.7)",
      link:"/deploy-observe-bill"
    },
  ];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container className="mt-5 " position={!isSmallScreen && "absolute"} top={"95%"}>
      <Grid container item xs={12} rowGap={3} sx={{ fontSize: "" }}>
        {ServicesData.map((val, idx) => (
          <Grid
            item
            md={3}
            xs={12}
            sm={6}
            key={idx}
            className={"mb-4"}
          >
            <ProductsCard
            link={`/consulting-services${val.link}`}
              title1={val.title1}
              title2={val.title2}
              titleMuiProps="fw-700 text-center py-3 text-wrap text-white card-text-align Consulting-text-size "
              sxtitle={{ fontSize: "130%" }}
              cardBgColor={val.cardcolor}
              footerMuiProps="text-white"
              hoverEffect={true}
              cardheightclass="Consulting-card-height"
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default ServicesConsultingCards;
