import { Box, Grid, Typography } from "@mui/material";
import React from "react";
const S3_URL = process.env.REACT_APP_S3_URL;

const call =
  S3_URL+"/v3/assestsWebp/contactUs/callIcon.webp";
const emailIcon =
  S3_URL+"/v3/assestsWebp/contactUs/emailIcon.webp";
const addressIcon =
  S3_URL+"/v3/assestsWebp/contactUs/addressIcon.webp";

function ContactusCard({
  Address1 = ``,
  Address2 = ``,
  Address3 = ``,
  phoneNumber = "",
  email = "",
  flag = "",
}) {
  return (
    <Grid container rowGap={1} className="h-100 w-100 Shadow-effects p-3">
      <Grid item xs={12} className="d-flex justify-content-center ">
        <Box height={"70px"} width={"120px"}>
          <img loading="lazy"  src={flag} alt="UsFalg" className=" w-100 h-100" />
        </Box>
      </Grid>
      <Grid container item xs={12} className="mt-3">
        <Grid
          item
          xs={2}
          className="d-flex justify-content-center align-items-center"
        >
          <Box
            height={"30px"}
            width={"30px"}
            className="d-flex justify-content-center align-items-center"
          >
            <img loading="lazy"  src={addressIcon} alt="" className="h-100 w-100" />
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          className="d-flex justify-content-start align-items-center"
        >
          <Typography className="fw-500 fs-14">
            {Address1}
            <span>
              <br />
              {Address2}
            </span>
            <br />
            <span>{Address3}</span>
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid
          item
          xs={2}
          className="d-flex justify-content-center align-items-center"
        >
          <Box
            height={"30px"}
            width={"30px"}
            className="d-flex justify-content-center align-items-center"
          >
            <img loading="lazy"  src={call} alt="" className="h-100 w-100" />
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          className="d-flex justify-content-start align-items-center"
        >
          <Typography className="fw-500 fs-14">{phoneNumber}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid
          item
          xs={2}
          className="d-flex justify-content-center align-items-center"
        >
          <Box
            height={"30px"}
            width={"30px"}
            className="d-flex justify-content-center align-items-center"
          >
            <img loading="lazy"  src={emailIcon} alt="" className="h-100 w-100" />
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          className="d-flex justify-content-start align-items-center pb-1"
        >
          <Typography className="fw-500 fs-14">{email}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContactusCard;
