import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import ButtonComponent from "../../atoms/ButtonComponent";
import { useNavigate } from "react-router-dom";
import RoundedRectangleBox from "../../atoms/RoundedRectangleBox";

function DiscoverMoreSection() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box position="relative" sx={{ backgroundColor: "white", width: "100%" }}>
        <Grid container padding={10}>
          &nbsp;
        </Grid>
        <Grid container sx={{ position: "absolute", top: "45%" }}>
          <Grid item xs={1} md={2}></Grid>
          <Grid
            item
            container
            xs={10}
            md={8}
            sx={{ overflow: "hidden" }}
            className="footer-first-part-body"
          >
            <Grid
              item
              xs={12}
              md={8}
              position={"relative"}
              className="d-flex justify-content-center align-items-center "
            >
              {!isSmallScreen && (
                <RoundedRectangleBox
                  animationClassName="grey-capsule"
                  height="300%"
                  width="50%"
                  top="-310%"
                  left="-25%"
                  backgroundColor="white"
                  sx={{ transform: "rotate(45deg)", opacity: "50%" }}
                />
              )}
              <Box
                zIndex={2}
                className=" fw-700 fs-20 text-center d-flex justify-content-center align-items-center "
                color={"rgba(25, 24, 37, 1)"}
              >
                We’re delivering the best customer experience
              </Box>
              {!isSmallScreen && (
                <RoundedRectangleBox
                  animationClassName="grey-capsule"
                  height="150%"
                  width="45%"
                  top="70%"
                  left="35%"
                  zIndex={0}
                  backgroundColor="#FFFFFF"
                  sx={{ transform: "rotate(45deg)" }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={3} className="d-flex justify-content-center">
              <ButtonComponent
                onBtnClick={() => navigate("/contact-us")}
                label="DISCOVER MORE"
                bgColor="bg-light"
                textColor="text-dark"
                muiProps="fw-700 py-3"
                fullWidth={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default DiscoverMoreSection;
