import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

function TabsCard({
  icon = "",
  iconContent = "Product Design",
  selected = false,
  index = 0,
  sxcard = {},
  sxtext = {},
  onCardClick = () => {},
}) {
  const [hover, setHover] = useState(false);

  const cardStyles = {
    cursor: "pointer",
    backgroundColor: selected
      ? "rgba(36, 171, 227, 1)"
      : hover
      ? "rgba(36, 171, 227, 0.6)"
      : "white",
    transition: "all 0.3s ease",
    ...sxcard,
  };

  const textColor = selected || hover ? "white" : "black";
  const iconColor = selected || hover ? "white" : "rgba(36, 171, 227, 1)";

  return (
    <Grid container className="h-100">
      <Grid
        container
        item
        sm={12}
        paddingY={1.5}
        onClick={() => {
          onCardClick(index);
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={cardStyles}
      >
        <Grid item xs={12} className="d-flex justify-content-center">
          {icon && (
            <Box>{React.cloneElement(icon, { svgcolor: iconColor })}</Box>
          )}
        </Grid>
        <Grid item xs={12} className="d-flex justify-content-center">
          <Typography
            className="fw-700 fs-12 text-center py-2"
            color={textColor}
            sx={{ ...sxtext }}
          >
            {iconContent}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TabsCard;
