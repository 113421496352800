import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../molecules/ContentSection";
import PageLayout from "../../organisms/PageLayout";
import ReusableBackground1 from "../../organisms/ReusableBackground1";
import CaseStudyCarousel from "../../organisms/CaseStudyCarousel";
import Casestudy1 from "../../molecules/Casestudy1";
import FarwarButton from "../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../assests/svg/Common/WestArrow";
import { caseStudy_Data } from "../../constants/casestudypages";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/casestudy/banner.webp";
const CaseStudyPageSubPage = () => {
  const navigate = useNavigate();
  const { caseStudySub } = useParams();
  const [caseStudy, setCaseStudy] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [caseStudySub]);

  useEffect(() => {
    if (caseStudy_Data && caseStudySub) {
      const filteredCaseStudies = caseStudy_Data.filter(
        (caseStudy) => caseStudy.link === caseStudySub
      );
      console.log(filteredCaseStudies);

      setCaseStudy(filteredCaseStudies[0]);
    } else {
      setCaseStudy({});
    }
  }, [caseStudySub, caseStudy_Data]);


  return (
    <>
      <Helmet>
        <title>{`Dyashin : ${caseStudy?.link} `}</title>
        <meta
          property="og:title"
          content="Case Studies - Dyashin's Success Stories and Client Transformations"
        />
        <meta
          property="og:description"
          content="Explore our case studies to see how Dyashin's innovative solutions have driven success and transformation for our clients across various industries."
        />
        <meta
          name="description"
          content="Explore our case studies to see how Dyashin's innovative solutions have driven success and transformation for our clients across various industries."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle="Case Study">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Case Study"}
            badgeTextColor="text-info"
            titleMuiProp="fs-30"
            sxChip={{
              backgroundColor: "rgba(141, 171, 225, 0.26)",
              marginTop: 8,
            }}
            chipMuiProps="content-chip1"
            title={caseStudy?.pageHeading}
            backbutton={
              <Grid
                item
                xs={12}
                className="d-flex flex-column align-items-start  justify-content-end "
              >
                <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
                <FarwarButton
                  icon={<WestArrow />}
                  onBtnClick={() => navigate("/case-study")}
                />
              </Grid>
            }
            backbtn={true}
          >
            <Box>
              <Box width={"100%"}>
                <img loading="lazy"  src={caseStudy?.mainpost} alt="" className="w-100 h-75" />
              </Box>

              <Typography
                className="fw-400 fs-14 mt-3 txt-description "
                textAlign={"justify"}
              >
                {caseStudy?.maindescription}
              </Typography>
            </Box>
            <Casestudy1 cardData={caseStudy?.cardData} />
          </ContentSection>
        </ReusableBackground1>
        <CaseStudyCarousel />
      </PageLayout>
    </>
  );
};

export default CaseStudyPageSubPage;
