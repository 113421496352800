import React from "react";
import {
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import FooterComponent from "../../molecules/FooterComponent";
import NavbarComponent from "../../molecules/NavbarComponent";
import DiscoverMoreSection from "../../molecules/DiscoverMoreSection";
import NavbarSecondComponent from "../../molecules/NavbarSecondComponent";
import ConsultationFree from "../../molecules/ConsultationFree";
import NavbarEvents from "../../molecules/NavbarEvents";
import COLORS from "../../../constants/colorConstants";

let {BLUE1} = COLORS
const PageLayout = ({
  pageTitle = "",
  backroundImg = "",
  children,
  DiscoverMore = true,
}) => {
  
  return (
    <div>
      <NavbarComponent />
      <NavbarEvents/>
      <NavbarSecondComponent />
  
      <Grid
        container
        style={{ overflow: "hidden" }}
        className="position-relative"
      >
        <Grid
          item
          xs={12}
          style={{
            backgroundImage: `url(${backroundImg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {backroundImg && (
            <Typography
              variant="h1"
              color="white"
              className="fw-700 fs-60 text-white text-center"
              style={{ textAlign: "center", padding: "5%" }}
            >
              {pageTitle}
            </Typography>
          )}
        </Grid>

        {children}

        {DiscoverMore ? <DiscoverMoreSection /> : <ConsultationFree />}
        <Grid
          item
          xs={12}
          paddingTop={3}
          style={{ backgroundColor: BLUE1 }}
        >
          <Grid container spacing={3}>
            <Grid item xs={1} sm={1} md={0.5}></Grid>
            <Grid item xs={10} sm={10} md={11}>
              <FooterComponent />
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12} sx={{ backgroundColor: "#050505" }}>
          <Grid item xs={12} className="d-flex justify-content-center">
            <Typography
              className="fw-400 fs-16 py-1 pe-2 text-center"
              sx={{ color: "#BDBDC1" }}
            >
              © Copyright 2023 by Dyashin
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ bgcolor: "#fff", height: "10px", width: "0.8px" }}
            />
            <Typography
              className="fw-400 fs-16 py-1 pe-2 ps-2 text-center"
              sx={{ color: "#BDBDC1" }}
            >
              Terms and Conditions
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ bgcolor: "#fff", height: "10px", width: "0.8px" }}
            />
            <Typography
              className="fw-400 fs-16 py-1 pe-2 ps-2 text-center"
              sx={{ color: "#BDBDC1" }}
            >
              Privacy Policy
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ bgcolor: "#fff", height: "10px", width: "0.8px" }}
            />
            <Typography
              className="fw-400 fs-16 py-1 ps-2"
              sx={{ color: "#BDBDC1" }}
            >
              v3.1
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PageLayout;
