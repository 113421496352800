import { Grid } from "@mui/material";
import React, { useState } from "react";
import ContentSection from "../../../molecules/ContentSection";
import APITestingIcon from "../../../../assests/svg/SoluctionPage/APITestingIcon";

import PerformanceTestingIcon from "../../../../assests/svg/SoluctionPage/PerformanceTestingIcon";
import ManualTestingIcon from "../../../../assests/svg/SoluctionPage/ManualTestingIcon";
import SecurityTestingIcon from "../../../../assests/svg/SoluctionPage/SecurityTestingIcon";
import AutomationTestingIcon from "../../../../assests/svg/SoluctionPage/AutomationTestingIcon";

import SoluctionSoftwareTesting from "../../../atoms/SoluctionSoftwareTesting";
import CaseStudycard from "../../../molecules/CaseStudyCard";
const S3_URL = process.env.REACT_APP_S3_URL;
const automationTestingImg =
  S3_URL + "/v3/assestsWebp/solutions/automationTesting.webp";
const manualTestingImg = S3_URL + "/v3/assestsWebp/solutions/manualTesting.webp";
const performanceTestingImg =
  S3_URL + "/v3/assestsWebp/solutions/performanceTesting.webp";
const securityTestingImg = S3_URL + "/v3/assestsWebp/solutions/securityTesting.webp";
const apiTestingImg = S3_URL + "/v3/assestsWebp/solutions/apiTesting.webp";

function SoftwareTesting() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const icondata = [
    {
      icon: (
        <ManualTestingIcon
          svgcolor={selectedIndex === 0 ? "white" : "rgba(36, 171, 227, 1)"}
        />
      ),
      imageURL: manualTestingImg,
      iconTitle: "Manual Testing",
      description: (
        <>
          We prioritize quality assurance through meticulous{" "}
          <a
            href={process.env.REACT_APP_BLOGSPOT_URL}
            target="blank"
            className="content-links fw-600"
          >
            manual testing
          </a>{" "}
          processes. Our skilled testing team rigorously evaluates software
          applications to ensure optimal functionality, identifying and
          rectifying issues to deliver a seamless user experience. Trust us for
          comprehensive manual testing services that guarantee the reliability,
          performance, and success of your software solutions in an
          ever-evolving digital landscape.
        </>
      ),
    },
    {
      icon: (
        <AutomationTestingIcon
          svgcolor={selectedIndex === 1 ? "white" : "rgba(36, 171, 227, 1)"}
        />
      ),
      imageURL: automationTestingImg,
      iconTitle: "Automation Testing",
      description: (
        <>
          Automation Testing revolutionizes software quality assurance by
          deploying automated processes to assess and validate software
          functionality. It accelerates testing cycles, enhances accuracy, and
          ensures robust application performance. Our comprehensive{" "}
          <a
            href={process.env.REACT_APP_QUORA_URL}
            target="blank"
            className="content-links fw-600"
          >
            {" "}
            Automation Testing
          </a>{" "}
          services optimize efficiency, minimize errors, and deliver reliable
          software solutions, ensuring a seamless and error-free user experience
          for your applications.
        </>
      ),
    },
    {
      icon: (
        <PerformanceTestingIcon
        />
      ),
      imageURL: performanceTestingImg,
      iconTitle: "Performance Testing",
      description: (
        <>
          We excel in Performance Testing, ensuring your{" "}
          <a
            href={process.env.REACT_APP_PINTEREST_URL}
            target="blank"
            className="content-links fw-600"
          >
            software applications
          </a>{" "}
          perform optimally under various conditions. Our expert team utilizes
          advanced tools and methodologies to assess scalability,
          responsiveness, and stability, delivering solutions that guarantee
          your applications meet and exceed performance expectations, enhancing
          user experience and satisfaction.
        </>
      ),
    },
    {
      icon: (
        <SecurityTestingIcon
          svgcolor={selectedIndex === 3 ? "white" : "rgba(36, 171, 227, 1)"}
        />
      ),
      imageURL: securityTestingImg,
      iconTitle: "Security Testing",
      description: (
        <>
          Security testing is a critical phase in the{" "}
          <a
            href={process.env.REACT_APP_BLOGSPOT_URL}
            target="blank"
            className="content-links fw-600"
          >
            software development
          </a>{" "}
          lifecycle, ensuring the robustness of digital systems against
          potential threats. By rigorously examining vulnerabilities,
          identifying weaknesses, and validating safeguards, our comprehensive
          security testing services provide the assurance of a secure and
          resilient infrastructure, safeguarding your data and maintaining the
          integrity of your digital assets.
        </>
      ),
    },
    {
      icon: (
        <APITestingIcon
          svgcolor={selectedIndex === 4 ? "white" : "rgba(36, 171, 227, 1)"}
        />
      ),
      imageURL: apiTestingImg,
      iconTitle: "API Testing",
      description: (
        <>
          We excel in API Testing, ensuring the seamless functionality,
          security, and performance of your applications. Our dedicated team
          employs industry-leading tools and methodologies to meticulously
          validate APIs, guaranteeing a robust and reliable foundation for your
          software solutions. Trust us to optimize your{" "}
          <a
            href=" https://dyashin.com/resources/blogs/mobile-app-development"
            target="blank"
            className="content-links fw-600"
          >
            API performance
          </a>{" "}
          and enhance the overall quality of your applications.
        </>
      ),
    },
  ];
  return (
    <div id="software-qa-and-testing">
      <Grid container bgcolor={"rgb(216, 234, 242)"} paddingY={7}>
        <Grid item md={2} xs={1}></Grid>
        <Grid item container md={8} xs={10}>
          <Grid item xs={12} md={5}>
            <ContentSection
              badgeLabel={"Solutions"}
              title={"Software QA & Testing"}
              textAlign={"start"}
              sxDescription={{ display: "flex", justifyContent: "start" }}
              sxChip={{ backgroundColor: "white" }}
              sxChild={{ display: "flex", justifyContent: "start" }}
              chipMuiProps="content-chip2"
            >
              <Grid item container md={10} paddingY={2} alignItems={"start"}>
                {icondata.map((val, idx) => (
                  <Grid item xs={4} sm={2.4} md={4} key={idx} className="p-1">
                    <SoluctionSoftwareTesting
                      icon={val.icon}
                      index={idx}
                      title={val.iconTitle}
                      onCardclick={(index) => {
                        setSelectedIndex(index);
                      }}
                      selected={selectedIndex === idx}
                    />
                  </Grid>
                ))}
              </Grid>
            </ContentSection>
          </Grid>
          <Grid item xs={12} md={7} bgcolor={"white"}>
            <CaseStudycard
              imageURL={icondata[selectedIndex].imageURL}
              headingContent={icondata[selectedIndex].iconTitle}
              dividerHeight={"1.5px"}
              descriptionContent={icondata[selectedIndex].description}
              link="/case-study"
              showfooter={true}
              cardmuiprop="decrease-scale"
              imageMuiProp="increase-scale"
              isCardNavigate={false}
            />
          </Grid>
        </Grid>
        <Grid item md={2} xs={1}></Grid>
      </Grid>
    </div>
  );
}

export default SoftwareTesting;
