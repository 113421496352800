import { Grid } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import SocialResponsibilityContent from "../../../molecules/SocialResponsibilityContent";
const S3_URL = process.env.REACT_APP_S3_URL;

const socialResponsibility =
  S3_URL+"/v3/assestsWebp/company/socialResponsibility.webp";

function SocialResponsibility() {
  return (
    <>
      <Grid container>
        <Grid item xs={1} md={2}></Grid>
        <Grid item xs={10} md={8}>
          <div id="csr-activities">
            <ContentSection
              sxChip={{ backgroundColor: "#8DABE142", color: "#24ABE3" }}
              badgeLabel="INTERACTIONS"
              title={`Social Responsibility`}
                chipMuiProps="content-chip1"
            >
              <Grid container>
                <Grid item xs={12} md={6} padding={2}>
                  <SocialResponsibilityContent />
                </Grid>
                <Grid item xs={12} md={6} alignContent={"center"} padding={2}>
                  <img loading="lazy" 
                    src={socialResponsibility}
                    alt=""
                    className="h-100 w-100"
                  />
                </Grid>
              </Grid>
            </ContentSection>
          </div>
        </Grid>
        <Grid item xs={1} md={2}></Grid>
      </Grid>
    </>
  );
}
export default SocialResponsibility;
