import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ScrollIndicator from "../ScrollIndicator";
import NavbarEvents from "../NavbarEvents";
import DyashinLogo2 from '../../../assests/navbar/DyashinLogo2.png'


function NavbarSecondComponent() {
  const theme = useTheme();
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [navbarColorChange, setNavbarColorChange] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const navlinks = [
    { name: "HOME", link: "/" },
    { name: "OUR COMPANY", link: "/our-company" },
    { name: "SERVICES", link: "/services" },
    { name: "PRODUCTS", link: "/products" },
    { name: "SOLUTIONS", link: "/solutions" },
    { name: "INDUSTRIES", link: "/industries" },
    { name: "CAREERS", link: "/careers" },
    { name: "CONTACT US", link: "/contact-us" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 185) {
        setNavbarColorChange(true);
      } else {
        setNavbarColorChange(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {!isSmallScreen && (
        <Grid
          container
          position={"sticky"}
          sx={{
            backgroundColor: navbarColorChange ? "#081D44" : "#FFFFFF",
            borderTop: "1px solid #BDBDC1B2",
            position: "sticky",
            top: 0,
            zIndex: 100,
          }}
        >
          {navbarColorChange && (
            <NavbarEvents
              backgroundColor="#ffffff"
              headingTextColor="#081d44"
              contentTextColor="#081d44"
              dateColor="#FA921F"
            />
          )}
          <Grid item xs={1}></Grid>
          <Grid
            item
            xs={10}
            padding={navbarColorChange ? 0.5 : 1.5}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {navbarColorChange && (
              <>
                <Grid item xs={2} >
                  <Link to="/" className="">
                    <img loading="lazy" 
                      src={DyashinLogo2}
                      alt="dyashin-logo"
                      className="navbar-company-logo w-80p "
                    />
                  </Link>
                </Grid>
              </>
            )}
            {navlinks.map((links, linksidx) => (
              <Link
                to={links.link}
                key={linksidx}
                style={{ textDecoration: "none",borderBottom:'2px solid transparent' }}
                className={`navbar-links ${
                  location.pathname === links.link ||
                  (links.link !== "/" &&
                    location.pathname.startsWith(links.link))
                    ? "active"
                    : ""
                }`}
                
                onClick={scrollToTop}
              >
                <Typography
                  className="pb-1 fw-700 fs-12"
                  style={{ color: navbarColorChange ? "white" : "black" }}
                >
                  {links.name}
                </Typography>
              </Link>
            ))}
          </Grid>
          <ScrollIndicator color="orange" />
        </Grid>
      )}
    </>
  );
}

export default NavbarSecondComponent;
