import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ProductsCard from "../ProductsCard";
import SoftwareDevelopment from "../../../assests/svg/ServicesPage/Softwaredevelopment";
import SoftwareTesting from "../../../assests/svg/ServicesPage/SoftwareTesting";
import Semiconducter from "../../../assests/svg/ServicesPage/Semiconducter";
import EngineeringServices from "../../../assests/svg/ServicesPage/EngineeringServices";

function ManagedServices() {
  const ServicesData = [
    {
      title: "Software Development",
      svgIcon: <SoftwareDevelopment />,
      link: "software-development",
    },
    {
      title: "Software QA & Testing",
      svgIcon: <SoftwareTesting />,
      link: "software-qa-and-testing",
    },
    {
      title: "Engineering Services",
      svgIcon: <EngineeringServices />,
      link: "engineering-services",
    },
    {
      title: "Semiconducter & Embeded System",
      svgIcon: <Semiconducter />,
      link: "semiconductor-and-embedded-systems",
    },
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container position={!isSmallScreen && "absolute"} top={{md:"110%"}}>
      <Grid container item xs={12}>
        {ServicesData.map((val, idx) => (
          <Grid
            item
            md={3}
            xs={12}
            sm={6}
            key={idx}
          >
            <ProductsCard
              link={`/solutions/${val.link}`}
              title1={val.title}
              svgIcon={val.svgIcon}
              sxcard={{backgroundColor:"white"}}
              titleMuiProps="fw-700 text-center py-2 text-wrap Managed-text-size"
              sxtitle={{ fontSize: "110%" }}
              hoverEffect={true}
              cardheightclass="Managed-card-height"
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default ManagedServices;
