const S3_URL = process.env.REACT_APP_S3_URL;
const case1mainpost =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy1/mainpost.webp";
const case1card1 = S3_URL + "/v3/assestsWebp/blogsArticlesSub/ExpertLed.webp";
const case1card2 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/interactiveLearning.webp";
const case1card3 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/supportandFeedback.webp";

const casemainPost2 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy2/casemainPost2.webp";
const case2card1 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy2/case2card1.webp";
const case2card2 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy2/case2card2.webp";
const case2card3 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy2/case2Card3.webp";

const case3mainpost =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy3/case3mainpost.webp";
const case3card1 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy3/case3card2.webp";
const case3card2 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy3/case3card1.webp";
const case3card3 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy3/case3card3.webp";

const case4mainpost =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy4/case4mainpost.webp";
const case4card1 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy4/case4card1.webp";
const case4card2 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy4/case4card2.webp";
const case4card3 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy4/casecard3.webp";

// const case5mainpost = S3_URL+'/v3/assests/casestudySubImg/casestudy5/case5mainpost.jpg'
const case5card1 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy5/case5card1.webp";
const case5card2 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy5/case5card2.webp";
const case5card3 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy5/case5card3.webp";

const case6mainpost =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy6/case6mainpost.webp";
const case6card1 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy6/case6card1.webp";
const case6card2 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy6/case6Card2.webp";
const case6card3 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy6/case6card3.webp";

const case7mainpost =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy7/case7mainpost.webp";
const case7card1 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy7/case7card1.webp";
const case7card2 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy7/case7card2.webp";
const case7card3 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy7/case7card3.webp";

const case8mainpost =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy8/case8mainpost.webp";
const case8card1 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy8/case8card1.webp";
const case8card2 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy8/case8card2.webp";
const case8card3 =
  S3_URL + "/v3/assestsWebp/casestudySubImg/casestudy8/case8card3.webp";

export const caseStudy_Data = [
  {
    id: 1,
    page: 2,
    link: "enhancing-productivity",
    pageHeading: "Enhancing Productivity and Efficiency with VBA IDE",
    maindescription:
      (<>An office suite software developer company approached <a href={process.env.REACT_APP_FACEBOOK_URL} target="blank" className="content-links fw-600">Dyashin Technosoft </a> seeking a solution for constant optimization of its operations to maintain competitiveness and client satisfaction.</>),
    mainpost: case1mainpost,
    cardData: [
      {
        image: case1card1,
        heading: "Challenge:",
        description:
          "Prior to implementing VBA IDE solutions, the firm encountered several challenges:",
        link: "",
        lists: [
          {
            heading: "Manual Data Processing:",
            subheading:
              " Many routine tasks, such as data entry, report generation, and data analysis, were performed manually, leading to inefficiencies and errors.",
          },
          {
            heading: "Lack of Customization:",
            subheading:
              " Standard functions often fell short in catering to the firm's specific requirements, necessitating repetitive manual interventions.",
          },
          {
            heading: "Time Constraints:",
            subheading:
              " The time-consuming nature  of  manual  tasks limited the team's capacity to focus on strategic initiatives and client-centric activities.",
          },
        ],
      },
      {
        image: case1card2,
        heading: "Solution:",
        description:
          (<> Recognizing the need for <a href="https://dyashin.com/solutions" target="blank" className="content-links fw-600">automation </a> and customization, the firm decided to harness the power of VBA IDE. A dedicated team of analysts and developers collaborated to develop tailored solutions to address the identified challenges:</>),
        link: "",
        lists: [
          {
            heading: "Task Automation:",
            subheading:
              " Mundane tasks, such as data extraction from external sources, reconciliation, and report generation, were automated using VBA macros. This significantly reduced manual intervention and minimized the risk of errors.",
          },
          {
            heading: "Custom Functions:",
            subheading:
              (<> VBA allowed the creation of custom functions tailored to the firm's specific requirements. These functions extended capabilities, enabling advanced data analysis, scenario modelling, and <a href="https://www.linkedin.com/company/100918843/admin/dashboard/" target="blank" className="content-links fw-600">risk assessment</a>. </>),
          },
          {
            heading: "User Interface Enhancement: ",
            subheading:
              " The VBA IDE facilitated the creation of user-friendly interfaces, improving accessibility and usability for non-technical users within the firm.",
          },
        ],
      },
      {
        image: case1card3,
        heading: "Outcome:",
        description: "",
        link: "",
        lists: [
          {
            heading: "Increased Efficiency:",
            subheading:
              " Automation of repetitive tasks led to significant time savings, allowing team members to focus on value-added activities, such as client engagement and strategic planning. ",
          },
          {
            heading: "Error Reduction:",
            subheading:
              (<> Automation minimized the risk of human errors inherent in manual processes, ensuring <a href="https://qr.ae/ps6lOk" target="blank" className="content-links fw-600">data accuracy</a> and integrity.</>),
          },
          {
            heading: "Enhanced Flexibility:",
            subheading:
              " The ability to customize functions and interfaces empowered users to adapt quickly to evolving business requirements and client needs.",
          },
          {
            heading: "Cost Savings:",
            subheading:
              " By reducing manual effort and increasing productivity, the firm achieved cost savings associated with labour and operational expenses.",
          },
        ],
      },
    ],
  },

  {
    id: 2,
    page: 2,
    link: "revolutionizing-education",
    pageHeading: "Revolutionizing Education with School Expert System (SES)",
    maindescription:
      "The client is a large educational institution consisting of several K-12 schools. They aim to enhance educational outcomes, streamline administrative processes, and provide personalized learning experiences for students. The institution has a diverse student body with varying educational needs and has been seeking innovative solutions to improve efficiency and effectiveness in both teaching and administration.",
    mainpost: casemainPost2,
    cardData: [
      {
        image: case2card1,
        heading: "Challenge:",
        description:
          " Prior to implementing SES solutions, the institution encountered several challenges:",
        link: "",
        lists: [
          {
            heading: "Personalized Learning:",
            subheading:
              " The institution faced difficulties in delivering personalized education to a large and diverse student population. Traditional teaching methods were not sufficiently addressing individual learning needs and styles.",
          },
          {
            heading: "Administrative Burden:",
            subheading:
              " Administrators were overwhelmed with manual processes, including scheduling, grading, and resource allocation. This inefficiency was consuming significant time and resources.",
          },
          {
            heading: "Data Management:",
            subheading:
              " Managing and analyzing vast amounts of student data to make informed decisions was a challenge. There was a need for a system that could provide actionable insights from this data.",
          },
        ],
      },
      {
        image: case2card2,
        heading: "Solution:",
        description:
        (<> The implementation of the <a href="https://dyashin.com/solutions" target="blank" className="content-links fw-600">School Expert System (SES)</a> involved several key components:</>),
        link: "",
        lists: [
          {
            heading: "Personalized Learning Plans:",
            subheading:
              " SES uses machine learning algorithms to analyze student performance and learning styles. It then generates personalized learning plans and recommends resources tailored to each student's needs.",
          },
          {
            heading: "Automated Administrative Tasks:",
            subheading:
              " SES automates routine administrative tasks such as attendance tracking, grading, and scheduling. This allows administrators to focus on more strategic activities.",
          },
          {
            heading: "Resource Optimization:",
            subheading:
              " SES helps in tracking and managing educational resources efficiently. It ensures that resources are allocated where they are needed the most, minimizing waste and improving overall resource utilization.",
          },
        ],
      },
      {
        image: case2card3,
        heading: "Outcome:",
        description: "",
        link: "",
        lists: [
          {
            heading: "Enhanced Learning Outcomes:",
            subheading:
              " With personalized learning plans, students received the support they needed, resulting in improved academic performance and engagement.",
          },
          {
            heading: "Increased Administrative Efficiency:",
            subheading:
              " Automation of administrative tasks reduced the workload on staff, allowing them to focus more on student-centric activities and strategic planning.",
          },
          {
            heading: "Data-Driven Decisions:",
            subheading:
              (<> Access to comprehensive <a href="https://www.linkedin.com/company/100918843/admin/dashboard/" target="blank" className="content-links fw-600">data analytics</a> enabled more informed decision-making, leading to better strategies and interventions.</>),
          },
          {
            heading: "Optimized Resource Allocation:",
            subheading:
              " Improved management and allocation of resources ensured that educational materials and support were available to those who needed them most, reducing disparities and promoting equity.",
          },
        ],
      },
    ],
  },

  {
    id: 3,
    page: 3,
    link:"empowering-fitness",
    pageHeading: "Empowering Fitness Journey ",
    maindescription:
      (<>Our client, a leading fitness and wellness company, offers a range of products and services designed to help individuals achieve their health and fitness goals. The company sought to create a comprehensive <a href="https://dyashin.com/industries" target="blank" className="content-links fw-600">digital platform</a> to empower users on their fitness journey by providing personalized workout plans, nutrition guidance, progress tracking, and community support.</>),
    mainpost: case3mainpost,
    cardData: [
      {
        image: case3card1,
        heading: "Challenge:",
        description:
          " The client faced several challenges in developing the fitness platform:",
        link: "",
        lists: [
          {
            heading: "Personalization:",
            subheading:
              " Delivering highly personalized workout and nutrition plans based on individual user profiles, fitness levels, and goals.",
          },
          {
            heading: "User Engagement:",
            subheading:
              " Keeping users engaged and motivated through interactive features, progress tracking, and community support.",
          },
          {
            heading: "Progress Tracking:",
            subheading:
              (<> <a href="https://twitter.com/Dyashintechno" target="blank" className="content-links fw-600">Manual tracking</a> of clients’ progress was time-consuming and prone to errors. Trainers needed a more efficient way to monitor and adjust fitness plans based on real-time data.</>),
          },
        ],
      },
      {
        image: case3card2,
        heading: "Solution:",
        description: "The implementation of  involved several key components:",
        link: "",
        lists: [
          {
            heading: "Personalized Algorithms:",
            subheading:
              " Personalized Algorithms: Developed advanced algorithms to create customized workout and nutrition plans based on user input, fitness assessments, and goals.",
          },
          {
            heading: "Enhanced Client Engagement:",
            subheading:
              " The platform includes gamification features, real-time feedback, and social sharing options to keep clients motivated and engaged. Personalized notifications and progress updates help maintain client interest and commitment.",
          },
          {
            heading: "Robust Security Measures:",
            subheading:
              (<>Implemented strong <a href={process.env.REACT_APP_INSTAGRAM_URL} target="blank" className="content-links fw-600">data encryption</a>, user authentication, and compliance with GDPR and other privacy regulations to ensure data security and privacy.</>) ,
          },
        ],
      },
      {
        image: case3card3,
        heading: "Outcome:",
        description:
          "The development of the Empowering Fitness Journey platform resulted in significant outcomes for the client:",
        link: "",
        lists: [
          {
            heading: "Improved Fitness Outcomes:",
            subheading:
              " Clients achieved better results due to personalized fitness plans and real-time adjustments. This led to higher satisfaction and success rates.",
          },
          {
            heading: "Increased Client Retention:",
            subheading:
              " Enhanced engagement features and personalized support contributed to higher client retention rates. Clients remained motivated and committed to their fitness journeys.",
          },
          {
            heading: "Efficient Progress Monitoring:",
            subheading:
              (<> <a href={process.env.REACT_APP_PINTEREST_URL} target="blank" className="content-links fw-600">Automated tracking</a> and real-time data allowed trainers to focus on providing high-quality support rather than manual record-keeping. This improved the overall efficiency and effectiveness of training sessions.</>),
          },
          {
            heading: "Optimized Resource Utilization:",
            subheading:
              " The efficient management of trainers and equipment ensured that clients received timely support and access to facilities, enhancing the overall client experience.",
          },
        ],
      },
    ],
  },

  {
    id: 4,
    page: 4,
    link: "revolutionizing-insurance",
    pageHeading:
      "Revolutionizing Insurance Management with Vehicle and Home Policies App",
    maindescription:
      (<>The client is a major insurance company providing a wide range of insurance products, including vehicle and home policies. They serve a large customer base, aiming to offer seamless and <a href={process.env.REACT_APP_QUORA_URL} target="blank" className="content-links fw-600">efficient services </a>while ensuring comprehensive coverage and customer satisfaction.</>),
    mainpost: case4mainpost,
    cardData: [
      {
        image: case4card1,
        heading: "Challenge:",
        description:
          "Prior to implementing the Vehicle and Home Policies App, the insurance company encountered several challenges:",
        link: "",
        lists: [
          {
            heading: "Complex Policy Management:",
            subheading:
              " Managing multiple policies for different clients with varying needs was cumbersome, leading to inefficiencies and potential errors in policy administration.",
          },
          {
            heading: "Claims Processing Delays:",
            subheading:
              " The traditional claims processing system was slow and often resulted in customer dissatisfaction due to lengthy wait times and lack of transparency.",
          },
          {
            heading: "Customer Engagement:",
            subheading:
              " Keeping customers informed and engaged with their policies was a challenge, leading to lower satisfaction and retention rates.",
          },
        ],
      },
      {
        image: case4card2,
        heading: "Solution:",
        description:
          "The implementation of the Vehicle and Home Policies App included several key features:",
        link: "",
        lists: [
          {
            heading: "Unified Policy Management:",
            subheading:
              " The app consolidates all policy information in a single platform, allowing customers to easily manage both vehicle and home insurance policies. It provides a clear overview and detailed information on coverage, premiums, and policy terms.",
          },
          {
            heading: "Enhanced Customer Engagement:",
            subheading:
              (<> The app offers personalized notifications, reminders for policy renewals, and educational content to keep customers informed and engaged. It also features a <a href="https://dyashin.com/resources/blogs/chatbot-implementation" target="blank" className="content-links fw-600">chatbot </a>for instant support and queries.</>),
          },
          {
            heading: "Advanced Risk Assessment:",
            subheading:
              " Utilizing data analytics and machine learning, the app enhances risk assessment capabilities. It analyzes a wide range of data points to provide more accurate risk evaluations and personalized premium calculations.",
          },
        ],
      },
      {
        image: case4card3,
        heading: "Outcome:",
        description:
          "The implementation of the Vehicle and Home Policies App led to significant improvements in the insurance company’s operations and customer satisfaction:",
        link: "",
        lists: [
          {
            heading: "Improved Policy Management:",
            subheading:
              " The unified platform simplified policy administration, reducing errors and increasing efficiency. Customers found it easier to manage their policies, leading to higher satisfaction.",
          },
          {
            heading: "Faster Claims Processing:",
            subheading:
              " The streamlined claims process significantly reduced processing times, enhancing customer experience and trust in the company.",
          },
          {
            heading: "Enhanced Risk Assessment:",
            subheading:
              (<>Advanced data analytics provided more accurate risk assessments and personalized premiums, leading to better <a href={process.env.REACT_APP_INSTAGRAM_URL} target="blank" className="content-links fw-600"> risk management </a> and optimized pricing strategies.</>),
          },
          {
            heading: "Increased Customer Engagement:",
            subheading:
              " Personalized interactions and educational content boosted customer engagement and retention. The app’s user-friendly interface and instant support improved overall customer satisfaction.",
          },
        ],
      },
    ],
  },

  {
    id: 5,
    page: 5,
    link: "education-technology",
    pageHeading: "Education Technology",
    maindescription:
      "The client is a prominent educational institution overseeing a network of K-12 schools. Committed to enhancing educational outcomes and operational efficiency, the institution seeks innovative solutions to personalize learning, streamline administrative tasks, and leverage data-driven insights for strategic decision-making.",
    mainpost: case4mainpost,
    cardData: [
      {
        image: case5card1,
        heading: "Challenge:",
        description: "",
        link: "",
        lists: [
          {
            heading: "Personalized Learning:",
            subheading:
              " Delivering customized education to a diverse student body was challenging, as traditional teaching methods did not adequately address individual learning needs and styles.",
          },
          {
            heading: "Administrative Burden:",
            subheading:
              (<> Administrators were overwhelmed with <a href="https://www.linkedin.com/company/100918843/admin/dashboard/" target="blank" className="content-links fw-600">manual processes </a>, including scheduling, grading, and resource allocation, which consumed significant time and resources.</>),
          },
          {
            heading: "Data Engagement:",
            subheading:
              " Handling and analyzing vast amounts of student data to inform decisions was complex and time-consuming, necessitating a more efficient system.",
          },
        ],
      },
      {
        image: case5card2,
        heading: "Solution:",
        description:
          "The implementation of the Vehicle and Home Policies App included several key features:",
        link: "",
        lists: [
          {
            heading: "Unified Policy Management:",
            subheading:
              " The app consolidates all policy information in a single platform, allowing customers to easily manage both vehicle and home insurance policies. It provides a clear overview and detailed information on coverage, premiums, and policy terms.",
          },
          {
            heading: "Enhanced Customer Engagement:",
            subheading:
              " The app offers personalized notifications, reminders for policy renewals, and educational content to keep customers informed and engaged. It also features a chatbot for instant support and queries.",
          },
          {
            heading: "Advanced Risk Assessment:",
            subheading:
              (<> Utilizing <a href=" https://dyashin.com/" target="blank" className="content-links fw-600">data analytics </a> and machine learning, the app enhances risk assessment capabilities. It analyzes a wide range of data points to provide more accurate risk evaluations and personalized premium calculations.</>),
          },
        ],
      },
      {
        image: case5card3,
        heading: "Outcome:",
        description:
          "The implementation of the Vehicle and Home Policies App led to significant improvements in the insurance company’s operations and customer satisfaction:",
        link: "",
        lists: [
          {
            heading: "Improved Policy Management:",
            subheading:
              " The unified platform simplified policy administration, reducing errors and increasing efficiency. Customers found it easier to manage their policies, leading to higher satisfaction.",
          },
          {
            heading: "Faster Claims Processing:",
            subheading:
              " The streamlined claims process significantly reduced processing times, enhancing customer experience and trust in the company.",
          },
          {
            heading: "Enhanced Risk Assessment:",
            subheading:
              (<> Advanced data analytics provided more accurate risk assessments and personalized premiums, leading to better <a href={process.env.REACT_APP_BLOGSPOT_URL} target="blank" className="content-links fw-600">risk management </a> and optimized pricing strategies.</>),
          },
          {
            heading: "Increased Customer Engagement:",
            subheading:
              " Personalized interactions and educational content boosted customer engagement and retention. The app’s user-friendly interface and instant support improved overall customer satisfaction.",
          },
        ],
      },
    ],
  },

  {
    id: 6,
    page: 6,
    link: "hmos-library",
    pageHeading: "HMOS Library Development",
    maindescription:
      (<>A global leader in ICT (Information and Communications Technology) infrastructure and smart devices, is renowned for its commitment to innovation and development in the tech industry. In 2019, Huawei introduced HarmonyOS (HMOS), an <a href=" https://dyashin.com/products" target="blank" className="content-links fw-600">operating system </a>designed to provide a seamless user experience across all devices and scenarios.</>),
    mainpost: case6mainpost,
    cardData: [
      {
        image: case6card1,
        heading: "Challenge:",
        description:
          "Huawei faced several challenges in developing the HMOS library:",
        link: "",
        lists: [
          {
            heading: "Cross-Platform Compatibility: ",
            subheading:
              " Ensuring the HMOS library could seamlessly integrate with a wide variety of devices, including smartphones, tablets, smartwatches, and IoT devices.",
          },
          {
            heading: "Performance Optimization: ",
            subheading:
              " Balancing the need for high performance with low power consumption across different hardware configurations.",
          },
          {
            heading: "Security and Privacy:",
            subheading:
              " Implementing robust security measures to protect user data and ensure privacy across the ecosystem.",
          },
        ],
      },
      {
        image: case6card2,
        heading: "Solution:",
        description:
          "To address these challenges, Huawei implemented the following solutions:",
        link: "",
        lists: [
          {
            heading: "Modular Architecture: ",
            subheading:
              " Developed a modular and scalable architecture for the HMOS library, enabling easy integration and updates without affecting the entire system.",
          },
          {
            heading: "Optimized Codebase: ",
            subheading:
              " Focused on optimizing the codebase for different hardware configurations, ensuring efficient performance and low power consumption.",
          },
          {
            heading: "Developer Support Programs: ",
            subheading:
              " Launched extensive support programs, including detailed documentation, SDKs, and technical support to assist developers in integrating the HMOS library into their applications.",
          },
        ],
      },
      {
        image: case6card3,
        heading: "Outcome:",
        description:
          "The development of the HMOS library yielded significant positive outcomes for Huawei:",
        link: "",
        lists: [
          {
            heading: "Enhanced Ecosystem Integration: ",
            subheading:
              (<> Achieved seamless integration across a wide range of Huawei devices, providing a consistent and high-quality <a href={process.env.REACT_APP_QUORA_URL} target="blank" className="content-links fw-600">user experience</a>.</>),
          },
          {
            heading: "Increased Developer Engagement: ",
            subheading:
              " Successfully attracted a growing number of developers to the HMOS platform, resulting in a diverse and robust application ecosystem.",
          },
          {
            heading: "Improved Performance and Efficiency: ",
            subheading:
              " Delivered a highly optimized and efficient operating system that balances performance with power consumption, enhancing the user experience across devices.",
          },
          {
            heading: "Strengthened Security: ",
            subheading:
              " Implemented robust security measures that protect user data and privacy, building trust and confidence among users and developers.",
          },
        ],
      },
    ],
  },

  {
    id: 7,
    page: 7,
    link: "cloud-infrastructure",
    pageHeading: "Cloud Infrastructure and Mobility Solutions",
    maindescription:
      (<>Semicab is a leading provider of <a href="https://dyashin.com/resources/blogs" target="blank" className="content-links fw-600">cloud infrastructure </a> and mobility solutions, specializing in the transportation and logistics sector. With a focus on optimizing fleet management, improving operational efficiency, and enhancing customer experience, Semicab has established itself as a key player in the digital transformation of the transportation industry.</>),
    mainpost: case7mainpost,
    cardData: [
      {
        image: case7card1,
        heading: "Challenge:",
        description:
          "Semicab encountered several challenges in the development and deployment of its cloud infrastructure and mobility solutions:",
        link: "",
        lists: [
          {
            heading: "Scalability: ",
            subheading:
              " Ensuring that the cloud infrastructure can scale seamlessly to accommodate growing volumes of data and users without compromising performance.",
          },
          {
            heading: "Data Security: ",
            subheading:
              " Implementing robust security measures to protect sensitive data, including customer information, payment details, and operational data.",
          },
          {
            heading: "Real-time Communication:",
            subheading:
              (<> Facilitating real-time communication and <a href={process.env.REACT_APP_BLOGSPOT_URL} target="blank" className="content-links fw-600">data exchange </a> between vehicles, drivers, dispatchers, and customers to optimize routing and scheduling.</>),
          },
        ],
      },
      {
        image: case7card2,
        heading: "Solution:",
        description:
          "To overcome these challenges, Semicab implemented the following solutions:",
        link: "",
        lists: [
          {
            heading: "Modular Architecture: ",
            subheading:
              (<> Scalable <a href={process.env.REACT_APP_INSTAGRAM_URL} target="blank" className="content-links fw-600">Cloud Architecture</a>: Designed a highly scalable cloud infrastructure using microservices architecture and containerization to handle increased workloads efficiently.</>),
          },
          {
            heading: "Data Encryption and Access Control:",
            subheading:
              " Implemented end-to-end encryption, multi-factor authentication, and role-based access control to safeguard data integrity and privacy.",
          },
          {
            heading: "API Integration and Middleware: ",
            subheading:
              " Developed APIs and middleware solutions to facilitate seamless integration with third-party systems, ensuring interoperability and data exchange.",
          },
        ],
      },
      {
        image: case7card3,
        heading: "Outcome:",
        description:
          (<>The deployment of Semicab's Cloud Infrastructure and <a href="https://dyashin.com/resources/blogs" target="blank" className="content-links fw-600">Mobility Solutions </a> resulted in significant outcomes for transportation companies:</>),
        link: "",
        lists: [
          {
            heading: "Improved Operational Efficiency: ",
            subheading:
              " Enabled transportation companies to optimize fleet management, reduce idle time, and increase resource utilization, leading to cost savings and improved service delivery.",
          },
          {
            heading: "Enhanced Customer Experience:",
            subheading:
              " Provided customers with real-time tracking, transparent pricing, and personalized services through mobile apps, enhancing overall satisfaction and loyalty.",
          },
          {
            heading: "Data-Driven Insights: ",
            subheading:
              " Empowered businesses with actionable insights derived from data analytics, enabling proactive decision-making, performance optimization, and predictive maintenance.",
          },
          {
            heading: "Scalability and Reliability: ",
            subheading:
              " Ensured scalability and reliability of the cloud infrastructure, even during peak demand periods, ensuring uninterrupted services and minimal downtime.",
          },
        ],
      },
    ],
  },

  {
    id: 8,
    page: 8,
    link: "e-commerce",
    pageHeading: "E-commerce Platform Development",
    maindescription:
      (<>Our client is a prominent retail company looking to expand its market reach and enhance customer engagement through an E-commerce platform. With a wide range of products and a growing customer base, the client sought to leverage <a href={process.env.REACT_APP_BLOGSPOT_URL} target="blank" className="content-links fw-600">digital technologies </a> to streamline sales, improve user experience, and drive business growth.</>),
    mainpost: case8mainpost,
    cardData: [
      {
        image: case8card1,
        heading: "Challenge:",
        description:
          "The client faced several challenges in developing the E-commerce platform:",
        link: "",
        lists: [
          {
            heading: "Scalability: ",
            subheading:
              " Building a scalable platform capable of handling a large volume of concurrent users and transactions during peak periods.",
          },
          {
            heading: "User Experience: ",
            subheading:
              " Designing an intuitive and responsive user interface that enhances navigation, product discovery, and checkout processes.",
          },
          {
            heading: "Integration Complexity:",
            subheading:
              (<>Integrating with various third-party services, including payment gateways, shipping providers, inventory <a href="https://dyashin.com/services" target="blank" className="content-links fw-600">management systems</a>, and customer relationship management (CRM) tools.</>),
          },
        ],
      },
      {
        image: case8card2,
        heading: "Solution:",
        description:
          "To address these challenges, the development team implemented the following solutions:",
        link: "",
        lists: [
          {
            heading: "Scalable Architecture:",
            subheading:
              (<> Designed a <a href="https://dyashin.com/products" target="blank" className="content-links fw-600">cloud-based architecture </a> using microservices, containerization, and auto-scaling capabilities to handle increased traffic and workload demands.</>),
          },
          {
            heading: "User-Centric Design: ",
            subheading:
              " Conducted user research and usability testing to create a user-friendly interface with intuitive navigation, responsive design, and personalized product recommendations.",
          },
          {
            heading: "Security Measures:",
            subheading:
              " Implemented HTTPS encryption, PCI-DSS compliance for payment processing, two-factor authentication, and regular security audits to safeguard customer data and prevent cyber threats.",
          },
        ],
      },
      {
        image: case8card3,
        heading: "Outcome:",
        description:
          "The development of the E-commerce platform resulted in significant outcomes for the client:",
        link: "",
        lists: [
          {
            heading: "Increased Sales and Revenue: ",
            subheading:
              " The user-friendly interface, personalized recommendations, and streamlined checkout process led to higher conversion rates and increased sales revenue.",
          },
          {
            heading: "Enhanced Customer Experience:",
            subheading:
              " Customers enjoyed a seamless shopping experience with fast loading times, easy navigation, secure payments, and prompt order fulfillment, leading to improved satisfaction and loyalty.",
          },
          {
            heading: "Operational Efficiency:",
            subheading:
              (<> <a href={process.env.REACT_APP_BLOGSPOT_URL} target="blank" className="content-links fw-600">Automation </a> of order management, inventory tracking, shipping, and customer support processes improved operational efficiency and reduced manual errors.</>),
          },
          {
            heading: "Scalability and Reliability: ",
            subheading:
              " The scalable architecture ensured the platform's performance and reliability, even during peak traffic periods, providing a consistent experience for users.",
          },
        ],
      },
    ],
  },
];
