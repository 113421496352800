import React, { useEffect, useState } from "react";
import { Grid, Stack, Pagination } from "@mui/material";
import ContentSection from "../../molecules/ContentSection";
import CaseStudycard from "../../molecules/CaseStudyCard";
import PageLayout from "../../organisms/PageLayout";
import ReusableBackground1 from "../../organisms/ReusableBackground1";
import { Helmet } from "react-helmet";
import blogsArticles9 from "../../../assests/compressed_image/b9/img_5.jpg";
import blogsArticles10 from "../../../assests/compressed_image/b10/img5.jpg";
import blogsArticles11 from "../../../assests/compressed_image/b11/img.jpg";
import blogsArticles12 from "../../../assests/compressed_image/b12/Img1.jpg";
import blogsArticles13 from "../../../assests/compressed_image/b13/img4.jpg";
import blogsArticles14 from "../../../assests/compressed_image/b14/imG1.jpg";
import blogsArticles15 from "../../../assests/compressed_image/b15/iMg3.jpg";
import blogsArticles16 from "../../../assests/compressed_image/b16/Img_555.jpg";
import blogsArticles17 from "../../../assests/compressed_image/b17/iMg_1.jpg";
import blogsArticles18 from "../../../assests/compressed_image/b18/img11.jpg";
import blogsArticles19 from "../../../assests/compressed_image/b19/imG-5.jpg";
import blogsArticles20 from "../../../assests/compressed_image/b20/img1.jpg";
import blogsArticles21 from "../../../assests/compressed_image/b21/img2.jpg";
import blogsArticles22 from "../../../assests/compressed_image/b22/img5.jpg";
import blogsArticles23 from "../../../assests/compressed_image/b23/img4.jpg";

const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";
const blogsArticles1 = S3_URL + "/v3/assestsWebp/blogsArticles/blogsArticles1.webp";
const blogsArticles2 = S3_URL + "/v3/assestsWebp/blogsArticles/blogsArticles2.webp";
const blogsArticles3 = S3_URL + "/v3/assestsWebp/blogsArticles/blogsArticles3.webp";
const blogsArticles4 = S3_URL + "/v3/assestsWebp/blogsArticles/blogsArticles4.webp";
const blogsArticles5 = S3_URL + "/v3/assestsWebp/blogsArticles/blogsArticles5.webp";
const blogsArticles6 = S3_URL + "/v3/assestsWebp/blogsArticles/blogsArticles6.webp";
const blogsArticles7 = S3_URL + "/v3/assestsWebp/blogsArticles/blogsArticles7.webp";
const blogsArticles8 = S3_URL + "/v3/assestsWebp/blogsArticles/blogsArticles8.webp";

const articles = [
  {
    title: "Empowering learning with Dyashin - Unlock your potential",
    description:
      "In the rapidly evolving landscape of technology, continuous learning is the key to staying ahead. Dyashin Technosoft Pvt Ltd understands this dynamic and is committed to empowering individuals and organizations through its comprehensive Learning Services. Unlocking Potential Through Learning: At Dyashin, we recognize that learning...",
    image: blogsArticles1,
    link: "/resources/blogs/empowering-learning",
  },
  {
    title:
      "Embracing Change: Dyashin Technosoft's Guide to Business Migration and Process Re-engineering",
    description:
      "In the fast-paced realm of technology, staying ahead requires not just keeping up with the trends but setting them. At Dyashin Technosoft Pvt Ltd, innovation is not just a buzzword; it's the very core of our Project Services. The Dyashin Difference: Our approach to Project Services is rooted in a commitment to delivering solutions that not...",
    image: blogsArticles2,
    link: "/resources/blogs/embracing-change",
  },
  {
    title:
      "AI in Recruitment: Unveiling DSJobby's Impact on Talent Acquisition",
    description:
      "In the ever-evolving landscape of technology, businesses face a myriad of challenges in staying ahead of the curve. Dyashin Technosoft Pvt Ltd, with its unwavering commitment to excellence, has been at the forefront of providing innovative consulting services to navigate these tech challenges successfully. Understanding the Tech Terrain...",
    image: blogsArticles3,
    link: "/resources/blogs/ai-in-recruitment",
  },
  {
    title:
      "Future-Proofing with Cloud: Dyashin Technosoft's Guide to Efficient Business Migration",
    description:
      "In the ever-evolving landscape of Information Technology, staying ahead of the curve is not just a necessity but a strategic imperative. Dyashin Technosoft, a trailblazer in the IT industry, is at the forefront of anticipating and embracing the future of IT. Let's delve into the trends and predictions that Dyashin envisions will shape the future...",
    image: blogsArticles4,
    link: "/resources/blogs/future-proofing-with-cloud",
  },
  {
    title:
      "Augmented Reality in Engineering: Dyashin Technosoft's Visionary Integration",
    description:
      "In the fast-evolving landscape of technology, staying ahead of the curve is imperative for businesses aiming to thrive. At Dyashin Technosoft Pvt Ltd, we recognize the pivotal role of a tech-savvy workforce in driving innovation and maintaining a competitive edge. Our commitment to this vision is manifested through our...",
    image: blogsArticles5,
    link: "/resources/blogs/augmented-reality-in-engineering",
  },
  {
    title:
      "Revolutionizing Customer Engagement: Dyashin's Generative Experience Strategy",
    description:
      "In the fast-paced realm of the digital age, businesses are constantly seeking innovative solutions to stay competitive and relevant. One name that consistently emerges as a beacon of transformation is Dyashin's Consulting. Known for their unparalleled expertise and visionary approach, Dyashin's Consulting has become synonymous...",
    image: blogsArticles6,
    link: "/resources/blogs/revolutionizing-customer-engagement",
  },
  {
    title:
      "Managed Services Revolution: Dyashin TechnoSoft's Project Management Innovation",
    description:
      "In the dynamic realm of IT services, project management stands as a linchpin for success. At Dyashin Technosoft, we recognize the pivotal role of project management in delivering exceptional solutions to our clients. In this blog, we delve into the core principles, best practices, and strategies that define our commitment to project management...",
    image: blogsArticles7,
    link: "/resources/blogs/managed-services-revolution",
  },
  {
    title:
      "Empowering Education: DSEdify's Innovative Approach to Practical Learning",
    description: `In the ever-evolving landscape of technology, staying ahead of the curve is not just an advantage but a necessity. Join us in an exclusive session of "Tech Talks with Dyashin," where we unravel the latest technological innovations shaping the future. Dyashin, a trailblazer in the tech industry, provides insights into the transformative...`,
    image: blogsArticles8,
    link: "/resources/blogs/empowering-education",
  },
  {
    title: "Transforming Business Through Generative Experience",
    description: `In today's fast-paced digital world, companies are always looking for new ways to connect with their customers and keep up with the competition. One such transformative approach gaining traction is the concept of Generative Experience...`,
    image: blogsArticles9,
    link: "/resources/blogs/transforming-business",
  },
  {
    title:
      "Awareness about the migration and re-engineering for enhancing quality in business",
    description: `In today's rapidly evolving business landscape. As technology advances and market demands shift, organizations often find themselves in the position of needing to migrate their systems or re-engineer their processes to maintain or enhance quality...`,
    image: blogsArticles10,
    link: "/resources/blogs/migrate-and-reengineer",
  },
  {
    title:
      "Building chatbots and chatrooms for enhanced communication in business",
    description: `In today's scenario of speed in business, effective communication is becoming more crucial than ever before. With teams across various locations and clients expecting instant responses.This is where chatbots and chatrooms come into play, revolutionizing how organizations interact internally and with their customers...`,
    image: blogsArticles11,
    link: "/resources/blogs/chatbot-implementation",
  },
  {
    title: "SAP implementation enhances the efficiency of businesses.",
    description: `In today's fast-paced business world, innovation isn't enough to keep up with the competition. What's more, it's no longer enough to stay ahead of the game; you need to streamline operations. For many businesses, SAP implementation has become a key enabler for achieving operational excellence and increasing efficiency across different functions...`,
    image: blogsArticles12,
    link: "/resources/blogs/sap-unlock-business",
  },
  {
    title: "A beginner’s guide to mobile application development in 2024",
    description: `The need for mobile app development has skyrocketed due to the world's rapid expansion in smartphone usage, to help newcomers get started in this fascinating profession, Dyashin Techno Soft offers thorough services and training on mobile applications...`,
    image: blogsArticles13,
    link: "/resources/blogs/mobile-app-development",
  },
  {
    title:
      "The Next Generation of LMS: How DSEdify is Enhancing Practical Learning through Innovation",
    description: `With its innovative platforms and teaching strategies, contemporary technology continues to shape education.  LMSs have demonstrated their worth, and the next generation of learning management systems (LMS) is poised to transform knowledge acquisition and sharing...`,
    image: blogsArticles14,
    link: "/resources/blogs/empower-education-dsedify",
  },
  {
    title:
      "Leveraging AI to Transform the Retail Experience: Insights from Dyashin Technosoft",
    description: `The retail landscape is undergoing a seismic shift, driven by advancements in artificial intelligence (AI) technology. Dyashin Technosoft, a leading innovator in AI solutions, is at the forefront of this transformation, enabling retailers to enhance customer experiences, optimize operations, and drive sales growth...`,
    image: blogsArticles15,
    link: "/resources/blogs/ai-implementation",
  },
  {
    title:
      "Critical Success Factors for ERP Implementation: A Guide by Dyashin Technosoft",
    description: `Implementing an Enterprise Resource Planning (ERP) system is a transformative endeavor for any organization. It combines different tasks into one system, improving productivity, data precision, and the process of making decisions. However, the complexity and scale of ERP implementation can make it a challenging process....`,
    image: blogsArticles16,
    link: "/resources/blogs/erp-implementation",
  },
  {
    title:
      "Advancements in FPGA Design: Pushing the Limits with Dyashin Technosoft",
    description: `Field-Programmable Gate Arrays (FPGAs) have transformed the digital design world, providing unmatched adaptability and efficiency. Dyashin Technosoft stands at the forefront of this evolution, pushing the limits of what is possible with FPGA technology.Dyashin Technosoft is transforming industries and setting new standards in FPGA design...`,
    image: blogsArticles17,
    link: "/resources/blogs/fpga-implementation",
  },
  {
    title: "The Role of Advanced HRMS in Global Compliance and Efficiency",
    description: `In today's fast-paced and interconnected world, businesses are increasingly looking to expand their operations globally. With this expansion comes the challenge of managing a diverse workforce across different countries, each with its own set of labour laws, cultural norms, and regulatory requirements...`,
    image: blogsArticles18,
    link: "/resources/blogs/hrms-implementation",
  },
  {
    title:
      "Transforming Business Processes through Effective SAP Implementation",
    description: `In today's fast-paced business environment, optimizing processes to enhance efficiency and unlock the full potential of SAP (Systems, Applications, and Products in Data Processing) is crucial. SAP, a leading enterprise resource planning (ERP) software, offers a suite of integrated applications that manage core business functions...`,
    image: blogsArticles19,
    link: "/resources/blogs/sap-implementation",
  },
  {
    title:
      "Enhancing Learning with DSEdify: The Future of Education by Dyashin Technosoft",
    description: `Added to the basic features of the Learning Management System (LMS), DSEdify by Dyashin Technosoft includes coding challenges that boost hands-on learning. Its strong test modules provide thorough evaluations The comprehensive approach enhances the educational experience, positioning DSEdify...`,
    image: blogsArticles20,
    link: "/resources/blogs/ds-edify",
  },
  {
    title: "The Role of Continuous Learning in Tech Success",
    description: `There are many digital platforms and organizations that provide top-notch software development training programs. For professionals in the tech industry, staying updated with the latest advancements and trends is not just an option but a necessity...`,
    image: blogsArticles21,
    link: "/resources/blogs/learn-tech",
  },
  {
    title: "Remote Work: Challenges and Solutions",
    description: `The increase in remote employment has changed the way companies’ function, providing adaptability and opportunities to hire from around the world. However, along with its benefits, remote work presents challenges those organizations and employee...`,
    image: blogsArticles22,
    link: "/resources/blogs/remote-work",
  },
  {
    title: "Building Resilient Supply Chains with AI",
    description: `In the fast-changing world of international trade, the ability of supply chains to withstand challenges has become a key issue for companies. The COVID-19 pandemic has shown the weaknesses of old-fashioned supply chains, emphasizing the importance of developing stronger, more flexible, and smarter systems...`,
    image: blogsArticles23,
    link: "/resources/blogs/supplychains-with-AI",
  },
];

const BlogsAndArticles = () => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => window.scrollTo(0, 0), []);

  const displayArticles = articles.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  useEffect(() => window.scrollTo(0, 0));
  return (
    <>
      <Helmet>
        <title>Dyashin : Blogs</title>
        <meta
          property="og:title"
          content="Dyashin Blogs - Insights and Innovations in Tech"
        />
        <meta
          property="og:description"
          content="Dive into Dyashin's blog for the latest articles on technology trends, innovations, and insights that drive the industry forward."
        />
        <meta
          name="description"
          content="Dive into Dyashin's blog for the latest articles on technology trends, innovations, and insights that drive the industry forward."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle="Blogs And Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"WHAT’S HAPPENING"}
            badgeTextColor="text-info"
            titleMuiProp="fs-40 "
            sxChip={{
              marginTop: "4%",
              backgroundColor: "rgba(141, 171, 225, 0.26)",
            }}
            title={"Blogs & Articles"}
            sx={{ marginTop: 2 }}
            sxChild={{ justifyContent: "start" }}
            chipMuiProps="content-chip1"
          >
            {displayArticles.map((article, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                key={index}
                className=" d-flex justify-content-center"
              >
                <CaseStudycard
                  descriptionContent={article.description}
                  headingContent={article.title}
                  imageURL={article.image}
                  link={article.link}
                  cardText="Know More"
                  imageHeight={150}
                  sxTitle={{
                    height: 30,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  isTooltip={true}
                />
              </Grid>
            ))}
          </ContentSection>
          <Stack
            spacing={2}
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={Math.ceil(articles.length / itemsPerPage)}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          </Stack>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsAndArticles;
