import { Grid, useMediaQuery, useTheme } from "@mui/material";
import RoundedRectangleBox from "../../atoms/RoundedRectangleBox";
const S3_URL = process.env.REACT_APP_S3_URL;

const carouselBackground = S3_URL+"/v3/assestsWebp/common/carouselBackground.webp";
function CarouselReusableComponent({
  children,
  sx = { backgroundImage: `url(${carouselBackground})` },
  animationClassName="grey-capsule",
  capsuleBackgroundColor="#24ABE3",
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Grid container sx={{ ...sx }}>
        {!isSmallScreen && (
          <Grid
            item
            md={2}
            sx={{
              position: "relative",
              width: "100%",
              overflow: "visible", 
              overflowY: "hidden",
            }}
          >
            <RoundedRectangleBox
              animationClassName={animationClassName}
              height={"90%"}
              top={"-10%"}
              left={"-20%"}
              backgroundColor={capsuleBackgroundColor}
              width={"90%"}
              sx={{ transform: "rotate(-45deg)" }}
            />
          </Grid>
        )}
        <Grid item md={10} xs={12}>
          {children}
        </Grid>
      </Grid>
    </>
  );
}
export default CarouselReusableComponent;
