import React, { useEffect, useRef } from "react";
import PageLayout from "../../organisms/PageLayout";
import ReusableBackground1 from "../../organisms/ReusableBackground1";
import AccelerateLearningServices from "../../molecules/AccelerateLearningServices";
import ServicesManagedServices from "../../molecules/ServicesManagedServices";
import ServicesConsultingServices from "../../molecules/ServicesConsultingServices";
import { Link, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/services/banner.webp";
const horizontalImg1 =
  S3_URL + "/v3/assestsWebp/services/horizontalImg1.webp";
const horizontalImg2 =
  S3_URL + "/v3/assestsWebp/services/horizontalImg2.webp";
const verticalImg1 =
  S3_URL + "/v3/assestsWebp/services/verticalImg1.webp";
const verticalImg2 =
  S3_URL + "/v3/assestsWebp/services/verticalImg2.webp";

function ServicesPage() {
  const { section } = useParams();
  const targetSectionRef = useRef(null);

  useEffect(() => {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        const yOffset = -60;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  });

  return (
    <>
      <Helmet>
        <title>Dyashin : Services</title>
        <meta property="og:title" content="Professional Services by Dyashin - Elevate Your Business Efficiency" />
        <meta name="description" content="Explore Dyashin's range of professional services designed to boost your business productivity and technological advancement. Partner with us for success." />
        <meta property="og:description" content="Explore Dyashin's range of professional services designed to boost your business productivity and technological advancement. Partner with us for success." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle="Services">
        <Grid container sx={{ paddingBottom: { md: 20 } }}>
          <Grid item xs={1} md={2}></Grid>
          <Grid item xs={10} md={8}>
            <div ref={targetSectionRef} id="managed-services">
              <ServicesManagedServices
                HorizontalImg={horizontalImg1}
                VerticalImg={verticalImg1}
                heading="Managed Services"
                badgeText="Innovate"
              >
                Our company excels in <Link to={'/resources/blogs/managed-services-revolution'} className="content-links fw-600 ">project services</Link>, delivering end-to-end solutions with precision. From planning to execution, we ensure seamless implementation, achieving client goals efficiently and effectively. We provide custom <Link to={'/solutions/software-development'} className="content-links fw-600 ">software services</Link> which is designed to assist our customers at all stages of the SDLC/PDLC assuring project success from start to end and beyond.
              </ServicesManagedServices>
            </div>
          </Grid>
          <Grid item xs={1} md={2}></Grid>
        </Grid>
        <Grid container bgcolor={"#8DABE142"} sx={{ paddingY: { md: 15, sm: 3, xs: 5 } }} >
          <Grid item xs={1} md={2}></Grid>
          <Grid item xs={10} md={8}>
            <div id="consulting-services">
              <ServicesConsultingServices
                HorizontalImg={horizontalImg2}
                VerticalImg={verticalImg2}
                heading="Consulting Services"
                badgeText="Collaborate"
              >
                At Dyashin Technosoft, our comprehensive <Link to={'/consulting-services'} className="content-links fw-600 ">Consulting Services</Link> encompass one-time hire solutions, contract staffing, and contract-to-hire staffing. Leveraging industry expertise, we provide tailored staffing solutions to meet your organizational needs, ensuring seamless integration of skilled professionals to drive success and innovation in your <Link to={'/solutions/software-development'} className="content-links fw-600 ">projects</Link>.
              </ServicesConsultingServices>
            </div>
          </Grid>
          <Grid item xs={1} md={2}></Grid>
        </Grid>
        <ReusableBackground1>
          <AccelerateLearningServices />
        </ReusableBackground1>
      </PageLayout>
    </>
  );
}

export default ServicesPage;
