import PageLayout from "../../organisms/PageLayout";
import ReusableBackground1 from "../../organisms/ReusableBackground1";
import ContentSection from "../../molecules/ContentSection";
import { Grid } from "@mui/material";
import CaseStudycard from "../../molecules/CaseStudyCard";
import React, { Suspense, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilteredDropDown from "../../molecules/FilteredDropDown";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const CaseStudyBanner = S3_URL + "/v3/assestsWebp/casestudy/banner.webp";
const caseStudy1 = S3_URL + "/v3/assestsWebp/casestudy/caseStudy1.webp";
const caseStudy2 = S3_URL + "/v3/assestsWebp/casestudy/caseStudy2.webp";
const caseStudy3 = S3_URL + "/v3/assestsWebp/casestudy/caseStudy3.webp";
const caseStudy4 = S3_URL + "/v3/assestsWebp/casestudy/caseStudy4.webp";
const caseStudy5 = S3_URL + "/v3/assestsWebp/casestudy/caseStudy5.webp";
const caseStudy6 = S3_URL + "/v3/assestsWebp/casestudy/caseStudy6.webp";
const caseStudy7 = S3_URL + "/v3/assestsWebp/casestudy/caseStudy7.webp";
const caseStudy8 = S3_URL + "/v3/assestsWebp/casestudy/caseStudy8.webp";

const articles = [
  {
    title: "Enhancing Productivity and Efficiency with VBA IDE",
    description:
      "An office suite software developer company approached Dyashin Technosoft seeking a solution for constant optimization of its operations to maintain competitiveness and client satisfaction.",
    image: caseStudy1,
    link: "/enhancing-productivity",
  },
  {
    title: "Revolutionizing Education with School Expert System(SES)",
    description:
      "The client is a leading educational institution approached Dyashin Technosoft seeking to leverage cutting-edge technologies to address the evolving needs of students and educators alike.",
    image: caseStudy2,
    link: "/revolutionizing-education",
    domain: "Education",
    technology: "SoftwareTesting",
    solutions: "SoftwareTesting",
  },
  {
    title: "Empowering Fitness Journey with StrongerMe",
    description:
      "The client is a fitness enthusiast and entrepreneur approached Dyashin Technosoft for creating a platform that offers personalized workout plans, nutrition guidance, and community support to help users achieve their fitness goals effectively.",
    image: caseStudy3,
    link: "/empowering-fitness",
    domain: "BSFI",
    technology: "EngineeringServices",
    solutions: "SoftwareTesting",
  },
  {
    title:
      "Revolutionizing Insurance Management: Vehicle and Home Policies App",
    description:
      "The client is a prominent insurance company approached Dyashin Technosoft seeking to provide customers with a user-friendly and efficient way to manage their insurance policies on mobile devices.",
    image: caseStudy4,
    link: "/revolutionizing-insurance",
    domain: "HealthCare",
    technology: "SoftwareDevelopment ",
    solutions: "SoftwareTesting",
  },
  {
    title: "Education Technology",
    description:
      "The client is a prominent Indian Multinational Corporation (MNC) with a focus on education services. They cater to a wide range of educational institutions, including schools affiliated with boards like CBSE, ICSE, and various state boards.",
    image: caseStudy5,
    link: "/education-technology",
    domain: "Education",
    technology: "EngineeringServices",
    solutions: "SoftwareDevelopment ",
  },
  {
    title: "HMOS library development ",
    description:
      "The client is a leading Chinese mobile development Research and Development (R&D) company specializing in the creation of innovative mobile operating system (OS) libraries. With a focus on Java and JavaScript technologies, they aim to provide comprehensive solutions catering to various mobile features and functionalities.",
    image: caseStudy6,
    link: "/hmos-library",
    domain: "Education",
    technology: "EngineeringServices",
    solutions: "SoftwareDevelopment ",
  },
  {
    title: "Cloud Infrastructure and Mobility Solutions",
    description:
      "Our client is a leading US-based mobility company specializing in commercial vehicle fleet management solutions. They provide advanced technology platforms for real-time tracking and management of commercial vehicles, primarily focusing on the transportation and logistics sector. With operations spanning across the United States and Europe, they required a robust and scalable infrastructure to support their rapidly growing user base and expanding business operations.",
    image: caseStudy7,
    link: "/cloud-infrastructure",
    domain: "BSFI",
    technology: "SoftwareDevelopment ",
    solutions: "Semiconducter",
  },
  {
    title: "E-commerce Platform Development",
    description:
      "Our client is a prominent Indian-based company looking to establish a robust B2C e-commerce platform with a unique reseller feature. As a leading player in their industry, they aimed to leverage digital channels to expand their market reach, enhance customer engagement, and drive sales growth. The reseller feature would enable individuals or businesses to sign up as resellers and promote/sell products from the platform, creating additional revenue streams and fostering a sense of community among users.",
    image: caseStudy8,
    link: "/e-commerce",
    domain: "BSFI",
    technology: "EngineeringServices",
    solutions: "Semiconducter",
  },

];

const categories = {
  domains: {
    All: [
      "BSFI",
      "Manufacturing",
      "Telecom",
      "Healthcare",
      "Retail & E-commerce",
      "Travel & Hospitality",
      "Energy & Utilities",
      "Education",
    ],
  },
  solutions: {
    All: [
      "AI & ML",
      "Migration & Re-Engineering",
      "Middleware Integration",
      "ERP Implementation",
      "E-Commerce Development",
      "Cloud Migration",
      "Salesforce",
      "Mobile/Hybrid App Development",
      "Chatbot Implementation",
      "SAP Implementation",
      "Support & Maintenance",
      "Digital Marketing",
      "Web App Development",
      "Automation Testing",
      "Performance Testing",
      "Security Testing",
      "API Testing",
      "Manual Testing",
      "Product Design",
      "CAD Modelling",
      "Simulation & Analysis",
      "Piping / Plant Engineering",
      "Automotive",
      "ASIC / SOC & FPGA Design",
      "System Design",
      "VLSI",
      "FPGA Validation",
      "IOT Testing",
      "Field Testing",
    ],
  },
  technologies: {
    "AI & ML": ["Python", "LISP", "Java", "TensorFlow", "PyTorch"],
    "Migration & Re-Engineering": ["Java", "Python", "AWS", "Azure"],
    "Middleware Integration": [
      "MuleSoft",
      "Apache Camel",
      "IBM WebSphere",
      "TIBCO",
    ],
    "ERP Implementation": ["SAP", "Oracle ERP", "Microsoft Dynamics", "Odoo"],
    "E-Commerce Development": [
      "Magento",
      "Shopify",
      "WooCommerce",
      "React",
      "Node.js",
    ],
    "Cloud Migration": ["AWS", "Azure", "Google Cloud", "Kubernetes"],
    Salesforce: ["Apex", "Visualforce", "Lightning", "Salesforce DX"],
    "Mobile/Hybrid App Development": [
      "Flutter",
      "React Native",
      "Ionic",
      "Swift",
      "Kotlin",
    ],
    "Chatbot Implementation": [
      "Dialogflow",
      "Microsoft Bot Framework",
      "Rasa",
      "Watson Assistant",
    ],
    "SAP Implementation": ["SAP HANA", "ABAP", "SAP Fiori", "SAP S/4HANA"],
    "Support & Maintenance": ["ITIL", "Jira", "ServiceNow"],
    "Digital Marketing": [
      "Google Analytics",
      "SEO",
      "PPC",
      "Content Marketing",
    ],
    "Web App Development": [
      "React",
      "Angular",
      "Vue.js",
      "Django",
      "Ruby on Rails",
    ],
    "Automation Testing": ["Selenium", "Cucumber", "Appium", "Robot Framework"],
    "Performance Testing": ["JMeter", "LoadRunner", "Gatling"],
    "Security Testing": ["OWASP ZAP", "Burp Suite", "Nessus"],
    "API Testing": ["Postman", "Swagger", "SoapUI"],
    "Manual Testing": ["TestRail", "Quality Center", "TestLink"],
    "Product Design": ["Adobe XD", "Sketch", "Figma"],
    "CAD Modelling": ["AutoCAD", "SolidWorks", "CATIA"],
    "Simulation & Analysis": ["MATLAB", "Simulink", "ANSYS"],
    "Piping / Plant Engineering": ["Intergraph", "Bentley", "AVEVA"],
    Automotive: ["CAN bus", "AUTOSAR", "Simulink"],
    "ASIC / SOC & FPGA Design": ["Verilog", "VHDL", "Synopsys", "Cadence"],
    "System Design": ["SysML", "UML", "MATLAB"],
    VLSI: ["Cadence", "Synopsys", "Mentor Graphics"],
    "FPGA Validation": ["Xilinx", "Altera", "ModelSim"],
    "IOT Testing": ["Node-RED", "AWS IoT", "Azure IoT"],
    "Field Testing": [
      "RF Test Equipment",
      "Network Analyzers",
      "Spectrum Analyzers",
    ],
  },
};

function useCheckedValues() {
  const [checkedValues, setCheckedValues] = useState({
    domains: [],
    solutions: [],
    technologies: [],
  });

  const [filteredTechnologies, setFilteredTechnologies] = useState({});

  const handleCheckedChange = (category, event) => {
    const { value } = event.target;
    setCheckedValues((prev) => ({
      ...prev,
      [category]: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const isGroupSelected = (category, group) => {
    if (category === "technologies") {
      return filteredTechnologies[group].every((name) =>
        checkedValues[category].includes(name)
      );
    } else {
      return categories[category][group].every((name) =>
        checkedValues[category].includes(name)
      );
    }
  };

  const handleGroupSelect = (category, event, group) => {
    if (!filteredTechnologies[group]) return;

    event.stopPropagation();
    setCheckedValues((prev) => ({
      ...prev,
      [category]: isGroupSelected(category, group)
        ? prev[category].filter(
          (name) => category === "technologies" ? !filteredTechnologies[group] : !categories[category][group].includes(name)
        )
        : [
          ...prev[category],
          ...category === "technologies" ? filteredTechnologies[group] : categories[category][group].filter(
            (name) => !prev[category].includes(name)
          ),
        ],
    }));
  };

  const handleItemClick = (category, event, listname) => {
    event.stopPropagation();
    setCheckedValues((prev) => ({
      ...prev,
      [category]: prev[category].includes(listname)
        ? prev[category].filter((selectedName) => selectedName !== listname)
        : [...prev[category], listname],
    }));
  };

  useEffect(() => {
    let filteredTechnologies = [];
    for (let value of checkedValues.solutions) {
      if (
        categories.technologies[value] &&
        categories.technologies[value].length > 0
      ) {
        filteredTechnologies.push(categories.technologies[value]);
      }
    }
    setFilteredTechnologies({ All: [...new Set(filteredTechnologies.flat())] });
    setCheckedValues((prev) => ({ ...prev, technologies: [] }));
  }, [checkedValues.solutions]);

  return {
    checkedValues,
    filteredTechnologies,
    handleCheckedChange,
    isGroupSelected,
    handleGroupSelect,
    handleItemClick,
  };
}

function CaseStudyPage() {
  useEffect(() => window.scrollTo(0, 0), []);
  const {
    checkedValues,
    filteredTechnologies,
    handleCheckedChange,
    isGroupSelected,
    handleGroupSelect,
    handleItemClick,
  } = useCheckedValues();

  return (
    <>
      <Helmet>
        <title>Dyashin : Case Studies </title>
        <meta property="og:title" content="Case Studies - Dyashin's Success Stories and Client Transformations" />
        <meta property="og:description" content="Explore our case studies to see how Dyashin's innovative solutions have driven success and transformation for our clients across various industries." />
        <meta name="description" content="Explore our case studies to see how Dyashin's innovative solutions have driven success and transformation for our clients across various industries." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}>
        <PageLayout backroundImg={CaseStudyBanner} pageTitle="Case Study">
          <ReusableBackground1>
            <Grid container className="mb-5" sx={{ marginTop: 5 }}>
              <Grid container item xs={12}>
                <Grid
                  item
                  xs={0.5}
                  className="d-flex align-items-center justify-content-end"
                >
                  <SearchIcon />
                </Grid>
                <Grid
                  container
                  item
                  xs={11}
                  sx={{
                    textAlign: { md: "center" },
                    justifyContent: { sm: "start" },
                  }}
                >
                  {["domains", "solutions", "technologies"].map((category) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      xl={4}
                      lg={6}
                      sx={{
                        textAlign: { lg: "start", sm: "center" },
                        justifyContent: { xs: "center", xl: "start" },
                      }}
                      className="d-flex"
                      key={category}
                    >
                      <FilteredDropDown
                        checkedValues={checkedValues[category]}
                        initialValues={
                          category === "technologies"
                            ? filteredTechnologies
                            : categories[category]
                        }
                        dropDowmName={
                          category.charAt(0).toUpperCase() + category.slice(1)
                        }
                        handleChange={(e) => handleCheckedChange(category, e)}
                        handleItemClick={(e, name) =>
                          handleItemClick(category, e, name)
                        }
                        handleGroupSelect={(e, group) =>
                          handleGroupSelect(category, e, group)
                        }
                        isGroupSelected={(group) =>
                          isGroupSelected(category, group)
                        }
                        isdatapresent={
                          category === "technologies"
                            ? checkedValues.solutions.length > 0
                              ? true
                              : false
                            : true
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={0.5}></Grid>
              </Grid>
            </Grid>

            <ContentSection
              badgeLabel={"Research"}
              badgeTextColor="text-info"
              titleMuiProp="fs-40"
              sxChip={{ backgroundColor: "rgba(141, 171, 225, 0.26)" }}
              title={"Case Study"}
              chipMuiProps="content-chip1"
            >
              {articles.map((article, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  paddingY={1}
                  key={index}
                  className="d-flex justify-content-center"
                >
                  <CaseStudycard
                    descriptionContent={article.description}
                    headingContent={article.title}
                    imageURL={article.image}
                    link={`/case-study${article.link}`}
                  />
                </Grid>
              ))}
            </ContentSection>
          </ReusableBackground1>
        </PageLayout>
      </Suspense>
    </>

  );
}
export default CaseStudyPage;
