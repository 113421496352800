import { Box } from "@mui/material";
import React from "react";

function SocialResponsibilitySubImage({ img = "" }) {
  return (
    <Box>
      <img loading="lazy"  src={img} alt="" className="h-100 w-100"></img>
    </Box>
  );
}

export default SocialResponsibilitySubImage;
