import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import RoundedRectangleBox from "../../atoms/RoundedRectangleBox";
const S3_URL = process.env.REACT_APP_S3_URL;
const callIcon =
  S3_URL+"/v3/assestsWebp/home/callIcon.webp";

function ConsultationFree() {
  return (
    <Box position="relative" sx={{ backgroundColor: "white", width: "100%" }}>
      <Grid container padding={10}>
        &nbsp;
      </Grid>
      <Grid container sx={{ position: "absolute", top: "45%" }}>
        <Grid item xs={1} md={2}></Grid>
        <Grid
          item
          container
          xs={10}
          md={8}
          sx={{ overflow: "hidden" }}
          className="contact-footer-part"
        >
          <Grid
            item
            xs={12}
            md={7}
            position={"relative"}
            className="d-flex justify-content-center align-items-center "
          >
            <RoundedRectangleBox
              animationClassName="grey-capsule"
              height="300%"
              width="50%"
              top="-310%"
              left="-25%"
              backgroundColor="white"
              sx={{ transform: "rotate(45deg)", opacity: "50%" }}
            />
            <Box
              zIndex={2}
              className=" fw-700 fs-20 lh-sm d-flex justify-content-center align-items-center "
              color={"rgba(25, 24, 37, 1)"}
            >
              <Typography className="fw-400 fs-20 text-white ">
                Get your <b style={{ color: "#24ABE3" }}>Free</b> business
                consultation.
              </Typography>
            </Box>
            <RoundedRectangleBox
              animationClassName="grey-capsule"
              height="150%"
              width="45%"
              top="70%"
              left="35%"
              zIndex={0}
              backgroundColor="#FFFFFF"
              sx={{ transform: "rotate(45deg)" }}
            />
          </Grid>

          <Grid item container xs={12} md={5}>
            <Grid item xs={4} className="d-flex align-items-center">
              <img loading="lazy"  src={callIcon} alt="" />
            </Grid>
            <Grid item xs={8}>
              <Typography className="fw-400 fs-18 py-1" sx={{ color: "white" }}>
                +1 &nbsp;&nbsp;302 279 0052
                <br />
                +91 960 691 5923
              </Typography>
              <Typography className="fw-400 fs-16 py-1 text-white">
                info@dyashin.com
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ConsultationFree;
