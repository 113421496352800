import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FarwarButton from "../../atoms/FarwardButton/farwarButton";

function ProductsCard({
  titleMuiProps = "fw-400 text-center py-3 ",
  image = "",
  footerMuiProps = "",
  title1 = "",
  title2 = "",
  footerTitle = "Know More",
  cardheightclass = "",
  sxcard = {},
  sxtitle = {},
  titleheight = "8vh",
  link = "#",
  svgIcon = "",
  cardBgColor = "",
  isProduct = false,
  hoverEffect = false,
}) {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);

  return (
    <Grid
      container
      justifyContent={"center"}
      className={`${cardheightclass}`}
      marginBottom={{ xs: 3, md: 0 }}
    >
      <Grid
        container
        item
        xs={10}
        bgcolor={cardBgColor}
        sx={{
          ...sxcard,
        }}
        className={`d-flex justify-content-center px-3 pt-4 pb-3 Shadow-effects cursor-pointer ${
          hoverEffect ? "inudtries-card" : ""
        }`}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onClick={() => {
          if (isProduct) {
            window.open(link, '_blank');
          } else  {
            navigate(link)
          }
        }}
      >
        {image ? (
          <Grid item xs={12} className="d-flex justify-content-center mb-2">
            <Box
              width={
                title1 === "E-commerce Platform"
                  ? "50%"
                  : title1 === "Micro Suite ERP"
                  ? "80%"
                  : "75%"
              }
            >
              <img loading="lazy"  src={image} alt="" className="w-100 h-100" />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={6} className="d-flex justify-content-center">
            {svgIcon &&
              React.cloneElement(svgIcon, {
                svgcolor: hover ? "white" : undefined,
              })}
          </Grid>
        )}
        {title1 && (
          <Grid item xs={12} className="">
            <Box
              height={titleheight}
              width={"100%"}
              className="d-flex justify-content-center align-items-center "
            >
              <Typography
                className={` ${titleMuiProps} `}
                paddingBottom={title2 && 2}
                sx={{ ...sxtitle }}
              >
                {title1}
                <br />
                {title2}
              </Typography>
            </Box>
          </Grid>
        )}

        <Grid
          container
          item
          xs={12}
          className={`border-top  border-2 ${footerMuiProps} `}
        >
          <Grid item xs={9} className="pt-3 ">
            <Typography
              className={`fs-14 fw-700  text-start ${footerMuiProps}`}
            >
              {footerTitle}
            </Typography>
          </Grid>
          <Grid item xs={3} className="pt-3">
            {isProduct ? (
              <a href={link} target="_blank" rel="noopener noreferrer">
                <FarwarButton
                  btnBgColor={
                    hover && hoverEffect
                      ? "#081D44"
                      : "rgba(141, 171, 225, 0.26)"
                  }
                  iconColor={hover && hoverEffect ? "white" : "#081D44"}
                />
              </a>
            ) : (
              <FarwarButton
                btnBgColor={
                  hover && hoverEffect ? "#081D44" : "rgba(141, 171, 225, 0.26)"
                }
                iconColor={hover && hoverEffect ? "white" : "#081D44"}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProductsCard;
