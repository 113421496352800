import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import FarwarButton from "../../atoms/FarwardButton/farwarButton";
const S3_URL = process.env.REACT_APP_S3_URL;
const service1 = S3_URL+"/v3/assestsWebp/home/service1.webp";

function ServiceCard({
  imageURL = service1,
  heading = "",
  title = "Managed Services",
  titleMuiProps = "",
  showIcon = true,
  description = "",
  headingMuiProps = "",
  contentMuiprops='',
  sxHeading = {},
  cardmuimargin='25%',
  sxDescription = {},
  descriptionMuiProps = "",
  link = "#",
  height = "35%",
  elevationValue=0,
}) {
  const navigate = useNavigate();

  const [hover, setHover] = useState(false);

  return (
    <>
      <Box
        className="position-relative sirvice-card heading w-100 "
        sx={{ mb:cardmuimargin , display: "flex", justifyContent: "center" }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <img loading="lazy"  src={imageURL} alt="" width="90%" className="card-image" elevation={elevationValue} />
        <Paper elevation={elevationValue}>
        <Grid
          container
          height={height}
          className={`card-texts cursor-pointer ${contentMuiprops} `}
          onClick={() => navigate(link)}
          sx={{
            backgroundColor: "white",
            position: "absolute",
            left: "5%",
            top: "80%",
            width: "80%",
            padding: "5%",
            boxShadow: "2px 2px 10px rgba(25, 24, 37, 0.08)",
          }}
        >
          <Grid container item xs={12}>
            {heading && (
              <Grid
                item
                xs={8}
                className={`fw-700 fs-12  ${headingMuiProps}`}
                sx={{ ...sxHeading }}
              >
                {heading}
              </Grid>
            )}
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={showIcon?8:12}>
              <Typography className={` ${titleMuiProps}`}>
                {title}
              </Typography>
            </Grid>
            {showIcon && (
              <Grid
                item
                xs={4}
                className={`d-flex align-items-center justify-content-end`}
              
              >
                <FarwarButton
                  btnBgColor={hover ? "#081D44" : "rgba(141, 171, 225, 0.26)"}
                  iconColor={hover ? "white" : "#081D44"}
                />
              </Grid>
            )}
          </Grid>
          {description && (
            <Grid item xs={12} className="">
              <Typography
                className={`  ${descriptionMuiProps}`}
                sx={{ ...sxDescription }}
              >
                {description}
              </Typography>
            </Grid>
          )}
        </Grid>
        </Paper>
       
      </Box>
    </>
  );
}

export default ServiceCard;
