import ImageSectionComponent from "../../../molecules/ImageSectionComponent";
import TwoSectionGridComponent from "../../../organisms/TwoSectionGridComponent";
import ContentSection from "../../../molecules/ContentSection";
import { Grid, Typography } from "@mui/material";
import CounterSection from "../CounterSection";
import { Link } from "react-router-dom";
const S3_URL = process.env.REACT_APP_S3_URL;
const VerticalImage = S3_URL+"/v3/assestsWebp/company/aboutVertical.webp";
const HorizontalImage = S3_URL+"/v3/assestsWebp/company/aboutHorizontal.webp";
function HomePageAboutUs() {
  return (
    <>
      <Grid 
        container
        paddingBottom={{ md: 12 }}
        position={"relative"}
        className="mt-5"
      >
        <Grid md={2} xs={1}></Grid>
        <Grid md={8} xs={10}>
          <TwoSectionGridComponent>
            <ImageSectionComponent
              verticalImg={VerticalImage}
              horizontalImg={HorizontalImage}
            />
            <ContentSection
              badgeLabel="ABOUT US"
              title="Work Together for your Business"
              textAlign="left"
              chipMuiProps="content-chip1"

            >
              <Typography
                className="fs-12 fw-400 txt-description text-start" 
                
              >
                Dyashin Technosoft is a renowned <Link to={'/solutions/software-development'} className="content-links fw-600 ">Software Development</Link> and <Link to={'/services'} className="content-links fw-600">Engineering services</Link> company led by a group of industry
                veterans.With our passion, knowledge, and commitment to
                excellence, we believe in transforming the arena of <a href="https://dyashin.com/case-study/1" target="blank" className="content-links fw-600">software
                development</a> and empowering customer businesses to embrace the
                future and achieve amazing success. Our comprehensive range of
                custom software services & Solutions are designed to assist our
                customers at all stages of the SDLC, assuring project success
                from start to end and beyond.
              </Typography>
            </ContentSection>
          </TwoSectionGridComponent>
        </Grid>
        <Grid md={2} sm={1}></Grid>

        <CounterSection />
      </Grid>
    </>
  );
}

export default HomePageAboutUs;
