import { Grid } from "@mui/material";
import React, { useRef } from "react";

function ContactUsMap() {
  const map = useRef();
  const disableScroll = () => {
    map.current.scrolling='no';
  };
  return (
    <Grid container>
      <Grid item xs={12} className="position-relative">
      <iframe 
      loading="lazy"
      title="officeMap"
      src="https://www.google.com/maps/d/u/0/embed?mid=1mAuW9znZTGDunMwmHXBVewriDqDGauo&ehbc=2E312F" 
      onMouseEnter={disableScroll}
      ref={map}
      width="100%" 
      height="480"
    ></iframe>
        <Grid
          item
          xs={12}
          top={"0px"}
          color={"rgba(141, 171, 225, 1)"}
          className="position-absolute w-100 "
          sx={{backgroundColor:"#FFFFFF",padding:'1.2rem'}}
        >
          &nbsp;
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContactUsMap;
