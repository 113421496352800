import {
  Accordion,
  AccordionSummary,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ChipComponent from "../../atoms/ChipComponent";
import ButtonComponent from "../../atoms/ButtonComponent";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CVfrom from "../CVfrom";
import { useToasts } from "react-toast-notifications";
import { postJobData } from "../../../services/career_jobs";
import InputBoxComponent from "../../atoms/InputBoxComponent";
import SimpleDropdown from "../../atoms/SimpleDropdown";

const jobTitles = [
  {
    id: "FullStack Developer",
    label: "FullStack Developer",
  },
  {
    id: "UI/UX designer",
    label: "UI/UX designer",
  },
  {
    id: "FrontEnd Developer",
    label: "FrontEnd Developer",
  },
  {
    id: "BackEnd Developer",
    label: "BackEnd Developer",
  },
];

function OpenPosition({ positionType = "open positions", positionData = [] }) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    email: "",
    resume: null,
  });

  const [fNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [jobTitleError, setJobTitleError] = useState("");
  const [resumeError, setResumeError] = useState("");

  const { addToast } = useToasts();

  const { firstName, lastName, email, jobTitle, resume } = formData;

  let handelChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  let validateFName = () => {
    if (!firstName.trim()) {
      setFirstNameError("Required First Name");
      return false;
    } else if (!/^[A-Za-z .]+$/.test(firstName)) {
      setFirstNameError("Only alphabets are allowed");
      return false;
    } else {
      setFirstNameError("");
      return true;
    }
  };

  let validateLName = () => {
    if (!lastName.trim()) {
      setLastNameError("Required Last Name");
      return false;
    } else if (!/^[A-Za-z]+$/.test(lastName)) {
      setLastNameError("Only alphabets are allowed");
      return false;
    } else {
      setLastNameError("");
      return true;
    }
  };

  let validateEmail = () => {
    if (email) {
      let regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+(\.\w{2,3})$/;
      if (regex.test(email)) {
        setEmailError("");
        return true;
      } else {
        setEmailError("Invalid Email");
        return false;
      }
    } else {
      setEmailError("Required Email");
      return false;
    }
  };

  let validateJobTitle = () => {
    if (jobTitle) {
      if (
        jobTitle === "FullStack Developer" ||
        jobTitle === "UI/UX designer" ||
        jobTitle === "FrontEnd Developer" ||
        jobTitle === "BackEnd Developer"
      ) {
        setJobTitleError("");
        return true;
      } else {
        setJobTitleError("Invalid Job Title");
        return false;
      }
    } else {
      setJobTitleError("Required Job Title");
      return false;
    }
  };

  let validateFile = () => {
    if (resume) {
      setResumeError(null);
      return true;
    } else {
      setResumeError("This field is required");
    }
  };

  const handleFiles = (files) => {
    const file = files[0];

    if (file.type !== "application/pdf") {
      setResumeError("Only PDF files are allowed.");
      setFormData({ ...formData, resume: null });
      return;
    }

    const fileSizeMB = file.size / (1024 * 1024);

    if (fileSizeMB > 5) {
      setResumeError("Resume size must be 5MB or below.");
      setFormData({ ...formData, resume: null });
      return;
    }

    setResumeError(null);
    setFormData({ ...formData, resume: file });
  };

  const handleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/pdf";
    input.style.display = "none";
    input.onchange = (event) => {
      const files = event.target.files;
      if (files.length) {
        handleFiles(files);
      }
    };
    document.body.appendChild(input);
    input.click();
    document.body.removeChild(input);
  };

  const inputsFields = [
    {
      filed: (
        <InputBoxComponent
          placeholder="Enter First Name"
          textLabel="First Name"
          name="firstName"
          value={formData.firstName}
          required={true}
          autoComplete="off"
          onChange={handelChange}
          onBlur={validateFName}
          fullWidth={true}
          errorText={fNameError}
        />
      ),
    },
    {
      filed: (
        <InputBoxComponent
          placeholder="Enter Last Name"
          textLabel="Last Name"
          name="lastName"
          value={formData.lastName}
          required={true}
          autoComplete="off"
          onChange={handelChange}
          onBlur={validateLName}
          fullWidth={true}
          errorText={lastNameError}
        />
      ),
    },
    {
      filed: (
        <SimpleDropdown
          placeholder="Job Title"
          textLabel="Job Title "
          name="jobTitle"
          options={jobTitles}
          value={
            jobTitles.find((option) => option.id === formData.jobTitle) || ""
          }
          onChange={(subject) => {
            setFormData({
              ...formData,
              jobTitle: subject ? subject.label : "",
            });
          }}
          onBlur={validateJobTitle}
          required={true}
          fullWidth={true}
          errorText={jobTitleError}
        />
      ),
    },
    {
      filed: (
        <InputBoxComponent
          placeholder="Enter Email ID"
          textLabel="Email ID"
          name="email"
          value={formData.email}
          required={true}
          autoComplete="off"
          onChange={handelChange}
          onBlur={validateEmail}
          fullWidth={true}
          errorText={emailError}
        />
      ),
    },
  ];

  let extrainput = (
    <FormControl fullWidth>
      <Grid mb="3px" px="6px">
        <Typography className="ff-Ro fs-14 fw-600" sx={{ color: "#1f3763" }}>
          Resume
          <span className="text-danger ms-1">*</span>
        </Typography>
      </Grid>
      <Button
        variant="outlined"
        onClick={handleClick}
        onBlur={validateFile}
        sx={{
          height: "40px",
          borderColor: "#A6A6A6",
          color: "darkblue",
          "&:hover": {
            color: "darkblue",
            borderColor: "#229cd0",
            backgroundColor: "transparent",
          },
        }}
        className="fw-100"
      >
        Upload Your resume
      </Button>

      {resumeError ? (
        <FormHelperText sx={{ color: "red" }}>{resumeError}</FormHelperText>
      ) : (
        <FormHelperText className="text-primary">
          {formData.resume ? formData.resume.name : ""}
        </FormHelperText>
      )}
    </FormControl>
  );

  const useResponsiveWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (width >= 768) {
      return "60vw";
    } else {
      return "90vw";
    }
  };

  const width = useResponsiveWidth();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { width },
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const [showModal, setShowModal] = useState(false);

  const mainModelOpen = () => {
    setShowModal(true);
  };

  const mainModelClose = () => {
    handleClearForm();
    setShowModal(false);
  };

  const handleClearForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      jobTitle: "",
      email: "",
      resume: null,
    });
    setFirstNameError("");
    setLastNameError("");
    setJobTitleError("");
    setEmailError("");
    setResumeError("");
  };

  const handelSubmit = () => {
    const isFirstNameValid = validateFName();
    const isLasttNameValid = validateLName();
    const isEmailIdValid = validateEmail();
    const isJobTitleValid = validateJobTitle();
    const isFileValid = validateFile();

    if (
      isFirstNameValid &&
      isLasttNameValid &&
      isEmailIdValid &&
      isJobTitleValid &&
      isFileValid
    ) {
      submitFromData();
    }
  };

  const submitFromData = async () => {
    let payload = new FormData();
    payload.append("firstName", firstName.trim());
    payload.append("lastName", lastName.trim());
    payload.append("jobTitle", jobTitle.trim());
    payload.append("email", email);
    payload.append("resume", resume);
    const { data, errRes } = await postJobData(payload);
    if (data) {
      if (data.error) {
        addToast(data.message, { appearance: "error" });
      } else {
        addToast(data.message, { appearance: "success" });
        setFormData({
          ...formData,
          firstName: "",
          lastName: "",
          jobTitle: "",
          email: "",
          resume: "",
        });
        setShowModal(false);
      }
    } else if (errRes) {
      addToast(errRes.message, { appearance: "error" });
    }
    handleClearForm();
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (formData.resume) {
      validateFile();
    }
  }, [formData.resume]);

  return (
    <>
      <Grid container className="">
        <Grid item xs={12}>
          <Typography
            className="fw-700 fs-30 lh-lg"
            color={"rgba(25, 24, 37, 1)"}
          >
            {positionType}
          </Typography>
        </Grid>
        <Grid item container xs={12} className="p-3" marginBottom={5}>
          {positionData.map((item, idx) => (
            <Accordion
              expanded={expanded === "panel" + idx + 1}
              onChange={handleChange("panel" + idx + 1)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Grid
                  container
                  key={idx}
                  mt={2.5}
                  padding={1}
                  borderBottom={!expanded ? "" : "1px solid #8DABE142"}
                  className="d-flex justify-content-center"
                >
                  <Grid item xs={12} mb={1} alignContent={"center"}>
                    <Typography
                      className="fw-700 fs-18"
                      textAlign={{ xs: "center", sm: "left" }}
                    >
                      {item.jobPosition}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    mb={1}
                    justifyContent={"center"}
                    alignContent={"center"}
                    textAlign={{ xs: "center", sm: "left" }}
                  >
                    <ChipComponent
                      label="FULL TIME"
                      index={idx}
                      chipMuiProps="content-chip1"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    mb={1}
                    textAlign={"center"}
                    alignContent={"center"}
                  >
                    <Typography className=" fs-14">
                      {item.jobExperience}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mb={1} textAlign={"center"}>
                    <ButtonComponent
                      label="APPLY NOW"
                      fullWidth={false}
                      bgColor="bg-info"
                      onBtnClick={mainModelOpen}
                    />
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {item.JobDetails.map((detail, idx) => (
                    <>
                      <Grid item sm={3} xs={12} key={idx}>
                        <Typography className=" fw-700 fs-18 ">
                          {detail.title}
                        </Typography>
                      </Grid>
                      <Grid item sm={9} xs={12}>
                        {detail.deatils.length > 1 ? (
                          detail.deatils.map((list, index) => (
                            <ul style={{ listStyleType: "square" }}>
                              <li>
                                <Typography
                                  key={index}
                                  className=" fw-400 fs-14"
                                >
                                  {list.list}
                                </Typography>
                              </li>
                            </ul>
                          ))
                        ) : (
                          <Typography className=" fw-400 fs-14 my-2">
                            {detail.deatils[0].list}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>

      <Modal
        open={showModal}
        onClose={(e, reason) => {
          reason !== "backdropClick" && setShowModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} onClick={(e) => e.stopPropagation()}>
          <Grid container>
            <Grid item xs={12}>
              <CVfrom
                onSubmit={handelSubmit}
                onModelClose={mainModelClose}
                inputsFields={inputsFields}
                message={extrainput}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default OpenPosition;
