import React from 'react'

function ChatbotHeader({ onClose }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#24ABE3', padding: '10px', color: 'white' }}>
            <span>Chat with Adya &nbsp;<span className='fs-8'>🟢Online</span></span>
            <button onClick={onClose} style={{ backgroundColor: 'transparent', border: 'none', color: 'white', cursor: 'pointer' }}>
                X
            </button>
        </div>
    )
}

export default ChatbotHeader