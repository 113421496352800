import { Grid, Typography } from "@mui/material";
import React from "react";
import ContentSection from "../ContentSection";
import { useNavigate } from "react-router-dom";
import FarwarButton from "../../atoms/FarwardButton/farwarButton";
import EastArrow from "../../../assests/svg/Common/EastArrow";

function ConsultingContentSection({
  heading = "One Time Hire",
  description = "",
  link = "",
  badgetext = "",
  footer = false,
  id = "",
}) {
  const navigate = useNavigate();
  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <ContentSection
          badgeLabel={badgetext}
          title={heading}
          sxTitle={{ fontSize: "24px" }}
          textAlign="start"
           chipMuiProps="content-chip1"
        />
      </Grid>
      <Grid item xs={12} className="mb-2">
        <Typography
          className="fw-400 fs-14 txt-description"
          textAlign={"justify"}
        >
          {description}
        </Typography>
      </Grid>
      {footer && (
        <Grid
          item
          xs={4}
          className="d-flex justify-content-between  align-items-center"
        >
          <Grid item xs={8}>
            <Typography className="fw-700 fs-12 text-uppercase chip-title-blue">
              Contact Us
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <FarwarButton
              btnBgColor="rgba(141, 171, 225, 0.26)"
              icon={<EastArrow />}
              onBtnClick={() => navigate("/contact-us")}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default ConsultingContentSection;
