import { Grid } from "@mui/material";
import React from "react";
import ContentSection from "../../../molecules/ContentSection";
import IndustriesCard from "../../../molecules/IndustriesCard";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link, useNavigate } from "react-router-dom";
const S3_URL = process.env.REACT_APP_S3_URL;

const iotTesting = S3_URL + "/v3/assestsWebp/solutions/iotTesting.webp";
const fpgaDesign = S3_URL + "/v3/assestsWebp/solutions/fpgaDesign.webp";
const VLSI = S3_URL + "/v3/assestsWebp/solutions/VLSI.webp";
const systemDesign = S3_URL + "/v3/assestsWebp/solutions/systemDesign.webp";
const fpgaValidation = S3_URL + "/v3/assestsWebp/solutions/fpgaValidation.webp";
const fieldTesting = S3_URL + "/v3/assestsWebp/solutions/fieldTesting.webp";
const fpgaDesignIcon = S3_URL + "/v3/assestsWebp/solutions/fpgaDesignIcon.webp";
const fpgaValidationIcon =
  S3_URL + "/v3/assestsWebp/solutions/fpgaValidationIcon.webp";
const VLSIIcon = S3_URL + "/v3/assestsWebp/solutions/VLSIIcon.webp";
const iotTestingIcon = S3_URL + "/v3/assestsWebp/solutions/iotTestingIcon.webp";
const systemDesignIcon = S3_URL + "/v3/assestsWebp/solutions/systemDesignIcon.webp";
const fieldTestingIcon = S3_URL + "/v3/assestsWebp/solutions/fieldTestingIcon.webp";

function Semiconductor() {
  const carddata = [
    {
      cardImage: fpgaDesign,
      cardHeading: "ASIC / SOC & FPGA Design",
      cardDescription:
        (<><span>We excel in the intricate realm of <a href=" https://dyashin.com/services" target="blank" className="content-links fw-600 inudtries-card-link-text">Embedded Systems </a> through our expertise in ASIC/SOC and FPGA Design. Our adept team, seasoned in the nuances of hardware development, crafts cutting-edge solutions that drive innovation and efficiency. Whether you seek bespoke designs, system-on-chip integration, or FPGA programming, our tailored approach ensures precision. We navigate the complexities of digital hardware design, optimizing performance and functionality. Partner with us for comprehensive solutions that transcend conventional boundaries, delivering unparalleled value in the ever-evolving landscape of Embedded Systems. Elevate your projects with <a href={process.env.REACT_APP_INSTAGRAM_URL} target="blank" className="content-links fw-600 inudtries-card-link-text"> Dyashin's </a> commitment to excellence and forward-thinking solutions in ASIC/SOC and FPGA Design.</span></>),
      cardIcon: fpgaDesignIcon,
    },
    {
      cardImage: VLSI,
      cardHeading: "VLSI",
      cardDescription:
      (<><span>We excels in the realm of VLSI for Embedded Systems, showcasing our proficiency in designing integrated circuits. Our expert team leverages cutting-edge technologies to create efficient and reliable embedded solutions. From conceptualization to implementation, we prioritize innovation and precision, ensuring our clients receive state-of-the-art <a href={process.env.REACT_APP_QUORA_URL} target="blank" className="content-links fw-600 inudtries-card-link-text">VLSI solutions </a> that seamlessly integrate with embedded systems. With a commitment to excellence and a deep understanding of the intricate nuances of VLSI, <a href=" https://dyashin.com/" target="blank" className="content-links fw-600 inudtries-card-link-text">Dyashin Technosoft </a> stands as a trusted partner for businesses seeking to optimize their embedded systems through advanced and customized VLSI solutions.</span></>),
      cardIcon: VLSIIcon,
    },
    {
      cardImage: iotTesting,
      cardHeading: "IOT Testing",
      cardDescription:
      (<><span>We excel in comprehensive IoT Testing for Embedded Systems, ensuring seamless functionality, security, and performance. Our expert team employs state-of-the-art testing methodologies to validate the integrity of your <a href="https://dyashin.com/solutions/semiconductor-and-embedded-systems" target="blank" className="content-links fw-600 inudtries-card-link-text">embedded devices </a>, assuring reliability and compliance with industry standards. From protocol testing to interoperability assessments, we offer end-to-end solutions to optimize the performance of your IoT-enabled products. Trust us to navigate the complexities of IoT Testing, safeguarding your embedded systems and contributing to the success of your connected devices in an ever-evolving technological landscape. Partner with <a href={process.env.REACT_APP_INSTAGRAM_URL} target="blank" className="content-links fw-600 inudtries-card-link-text">Dyashin Technosoft </a> for robust, reliable, and future-ready IoT Testing solutions.</span></>),
      cardIcon: iotTestingIcon,
    },
    {
      cardImage: systemDesign,
      cardHeading: "System Design",
      cardDescription:
      (<><span>Our System Design for Embedded Systems stands at the forefront of technological excellence. Meticulously crafted by our team of <a href={process.env.REACT_APP_QUORA_URL} target="blank" className="content-links fw-600 inudtries-card-link-text">skilled engineers </a>, our solutions are tailored to meet the dynamic needs of the modern world. We leverage cutting-edge technologies to design embedded systems that seamlessly integrate hardware and software components, ensuring optimal performance and efficiency. From concept to execution, our systematic approach guarantees robust solutions that drive innovation. Trust Dyashin Technosoft for unparalleled expertise in <a href="https://dyashin.com/solutions/semiconductor-and-embedded-systems" target="blank" className="content-links fw-600 inudtries-card-link-text">Embedded System </a> Design, elevating your projects to new heights of reliability, scalability, and functionality in the ever-evolving landscape of technology.</span></>),
      cardIcon: systemDesignIcon,
    },
    {
      cardImage: fpgaValidation,
      cardHeading: "FPGA Validation",
      cardDescription:
      (<><span>At Dyashin Technosoft, our FPGA Validation services for <a href={process.env.REACT_APP_FACEBOOK_URL} target="blank" className="content-links fw-600 inudtries-card-link-text">embedded systems </a> stand at the forefront of cutting-edge technology. Leveraging our expertise, we meticulously validate FPGA designs, ensuring seamless integration and optimal performance. Our dedicated team, comprised of skilled engineers, collaborates closely with clients to understand their unique requirements. With a commitment to quality, precision, and innovation, we navigate the complexities of FPGA validation to deliver solutions that exceed expectations. Trust Dyashin Technosoft for reliable and efficient FPGA Validation services, propelling your <a href="https://dyashin.com/solutions/semiconductor-and-embedded-systems" target="blank" className="content-links fw-600 inudtries-card-link-text">embedded systems </a> to new heights of performance and functionality in the ever-evolving technological landscape.</span></>),
      cardIcon: fpgaValidationIcon,
    },
    {
      cardImage: fieldTesting,
      cardHeading: "Field Testing",
      cardDescription:
      (<><span>we excel in comprehensive field testing for embedded systems, ensuring optimal performance and reliability. Our expert team conducts rigorous assessments to validate functionality, integration, and efficiency, guaranteeing that our <a href="https://dyashin.com/solutions/semiconductor-and-embedded-systems" target="blank" className="content-links fw-600 inudtries-card-link-text">embedded systems </a>meet the highest industry standards. We deploy cutting-edge methodologies and industry best practices to assess real-world scenarios, addressing potential challenges and refining system capabilities. By prioritizing <a href=" https://dyashin.com/solutions/software-qa-and-testing" target="blank" className="content-links fw-600 inudtries-card-link-text">field testing </a>, we fortify the robustness of our embedded solutions, enabling seamless integration into diverse environments. Trust Dyashin Technosoft for embedded systems that surpass expectations, delivering exceptional performance and durability across a spectrum of applications.</span></>),
      cardIcon: fieldTestingIcon,
    },
  ];
  const navigate = useNavigate();
  return (
    <Grid container className="d-flex justify-content-center ">
      <Grid item md={2} xs={1}></Grid>
      <Grid item container md={8} xs={10}>
        <div id="semiconductor-and-embedded-systems">
          <ContentSection
            badgeLabel={"Solutions"}
            title={"Semiconductor & Embedded Systems"}
            chipMuiProps="content-chip1"
          >
            {carddata.map((val, idx) => (
              <Grid item xs={12} sm={6} md={4} padding={1.5}>
                <Grid
                  container
                  className="h-100 inudtries-card cursor-pointer"
                  boxShadow={"0px 1.5px 20px #d1d0d6"}
                  // onClick={()=>{navigate('/case-study')}}
                >
                  <Grid item xs={12}>
                    <IndustriesCard
                      description={val.cardDescription}
                      heading={val.cardHeading}
                      icon={val.cardIcon}
                      imageURL={val.cardImage}
                      key={idx}
                      iconPositionStart={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className=" fs-12 chip-title-blue p-3 inudtries-card-footer-text"
                    alignContent={"end"}
                  >
                    <Link to="/case-study" style={{ textDecoration: "none" }}>
                      <span className="text-black fw-700 ">Case study</span>
                      <LaunchIcon
                        fontSize="12px"
                        sx={{ marginLeft: 1 }}
                        className="inudtries-card-footer-text"
                      />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </ContentSection>
        </div>
      </Grid>
      <Grid item md={2} xs={1}></Grid>
    </Grid>
  );
}

export default Semiconductor;
