
import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import ContentSection from "../../../molecules/ContentSection";
import PageLayout from "../../../organisms/PageLayout";
import ReusableBackground1 from "../../../organisms/ReusableBackground1";
import BolgsAndArticles1 from "../../../molecules/BolgsAndArticles1";
import FarwarButton from "../../../atoms/FarwardButton/farwarButton";
import WestArrow from "../../../../assests/svg/Common/WestArrow";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/blogsArticles/banner.webp";

const ExpertLed =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img1.webp";
const card2 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img2.webp";
const card3 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img3.webp";
const card4 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img3.webp";
const card5 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage20/img4.webp";
const card6 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage21/img2.webp";
const card7 =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img4.webp";
const mainPost =
  S3_URL + "/v3/assestsWebp/blogsArticlesSub/blogArticlePage23/img3.webp";

const BlogsArticlesPage23 = () => {
  const description = (
    <>
      In the fast-changing world of international trade, the ability of supply
      chains to withstand challenges has become a key issue for companies. The
      COVID-19 pandemic has shown the weaknesses of old-fashioned supply chains,
      emphasizing the importance of developing stronger, more flexible, and
      smarter systems. This is where Artificial Intelligence (AI) comes in, a
      game-changing technology that is changing the way companies handle supply
      chain operations. Dyashin Technosoft is leading the way in this change,
      assisting companies in using AI to create supply chains that are both
      resilient and prepared for the future.
    </>
  );
  const cardData = [
    {
      image: ExpertLed,
      heading: "Exploring the concept of supply chain resilience",
      description:
        "Supply chain resilience is about a supply chain's capacity to foresee, adjust, and bounce back from unforeseen disruptions. Conventional supply chains, typically linear and inflexible, struggle to manage sudden shifts in demand, shortages in supplies, or logistical hurdles. This inflexibility can result in considerable losses and operational interruptions.Conversely, AI-driven supply chains offer a fresh strategy. By leveraging machine learning, predictive analytics, and instant data processing, AI allows companies to establish supply chains that are not just more resilient but also more efficient and agile in responding to market changes. How AI boosts supply chain resilience",
      link: "",
    },
    {
      image: card2,
      heading: "Predictive analytics for risk mitigation:",
      description:
        "Artificial intelligence can analyze vast amounts of information to forecast possible interruptions in advance. By spotting patterns and trends, AI can forecast risks like supplier failures, natural disasters, or geopolitical incidents. This enables companies to take preemptive actions, such as diversifying their supplier base or adjusting logistics, to lessen these risks.",
      link: "",
    },
    {
      image: card3,
      heading: "Real-time data and transparency:",
      description:
        "A significant challenge in overseeing supply chains is the lack of instant data visibility. Artificial Intelligence-powered systems can combine information from different origins, offering a comprehensive perspective on the whole supply chain. This real-time visibility allows companies to keep an eye on inventory levels, track deliveries, and tackle issues as they emerge, reducing downtime and ensuring uninterrupted operations.",
      link: "",
    },
    {
      image: card4,
      heading: "Automation and optimization:",
      description:
        "AI can automate various supply chain management tasks, from predicting demand to managing inventory. This not only minimizes human error but also streamlines processes, leading to cost reductions and enhanced efficiency. For instance, AI algorithms can dynamically adjust inventory levels based on demand changes, ensuring that businesses maintain optimal stock levels at all times.",
      link: "",
    },
    {
      image: card5,
      heading: "Improved decision-making:",
      description:
        "AI equips decision-makers with actionable insights from data analysis. By analyzing complex datasets, AI can reveal patterns and correlations that might be overlooked by human analysts. This empowers businesses to make well-informed decisions that improve supply chain resilience, such as identifying backup suppliers or refining transportation strategies.",
      link: "",
    },
    {
      image: card6,
      heading:
        "Dyashin Technosoft: At the forefront of AI-powered supply chain solutions",
      description: (
        <>
          Dyashin Technosoft is dedicated to assisting businesses in leveraging
          AI to create resilient supply chains. With a comprehensive
          understanding of both technology and industry dynamics, Dyashin
          Technosoft provides tailored AI solutions that meet the specific needs
          of each client. From deploying predictive analytics tools to
          developing real-time monitoring systems, Dyashin Technosoft offers
          comprehensive support to ensure that businesses can adapt and succeed
          in an uncertain environment.
        </>
      ),
      link: "",
    },
    {
      image: card7,
      heading: "Conclusion",
      description:
        "Artificial Intelligence transcends its role as simply a tool for improving the effectiveness of supply chains; it acts as a crucial driving force for ensuring steadiness in the face of uncertainty. As businesses navigate the complexities of the modern world, those that integrate AI-based solutions into their supply chain operations will be better equipped to withstand obstacles and seize new opportunities. Dyashin Technosoft's expertise enables companies to build supply chains that are not only strong but also adaptable and competitive, ensuring ongoing success in a constantly evolving global market.",
      link: "",
    },
  ];
  useEffect(() => window.scrollTo(0, 0), []);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dyashin : AI-Supplychains</title>
        <meta
          property="og:title"
          content="Building Resilient Supply Chains with AI Technology"
        />
        <meta
          name="description"
          content="Explore how AI strengthens supply chains by improving resilience, enhancing efficiency, and enabling smarter decision-making in dynamic global markets."
        />
        <meta
          property="og:description"
          content="Explore how AI strengthens supply chains by improving resilience, enhancing efficiency, and enabling smarter decision-making in dynamic global markets."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle=" Blogs & Articles">
        <ReusableBackground1>
          <ContentSection
            badgeLabel={"Blogs & Articles"}
            badgeTextColor="text-info"
            title={"Building Resilient Supply Chains with AI"}
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
            backbutton={
              <Grid
                item
                xs={12}
                className="d-flex flex-column align-items-start  justify-content-center "
              >
                <Typography className="mb-1 ms-1 fw-700 fs-14">Back</Typography>
                <FarwarButton
                  icon={<WestArrow />}
                  onBtnClick={() => navigate("/resources/blogs")}
                />
              </Grid>
            }
            backbtn={true}
          >
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12}>
                <Box
                  height={"35vh"}
                  sx={{
                    backgroundImage: `url(${mainPost})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} paddingY={2}>
                <Typography
                  className="fw-400 fs-12 txt-description "
                  textAlign={"justify"}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <BolgsAndArticles1 cardData={cardData} />
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default BlogsArticlesPage23;
