import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link, useNavigate } from "react-router-dom";

function CaseStudycard({
  imageURL = "",
  headingContent = "",
  descriptionContent = "",
  dividerWidth = "180px",
  dividerHeight = "",
  showfooter = true,
  imageMuiProp = "",
  cardmuiprop = "",
  link = "#",
  sxTitle = {},
  sxDescription = {},
  lists = [],
  cardText = "Case Study",
  isCardNavigate = true,
  imageHeight,
  isTooltip = false,
}) {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        padding={1}
        className={` image-container${cardmuiprop}`}
        sx={{ cursor: "pointer" }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onClick={() => {
          isCardNavigate && navigate(link);
        }}
      >
        {imageURL && (
          <Grid
            item
            xs={12}
            className={`position-relative ${""}  overflow-hidden`}
            height={imageHeight}
          >
            <img loading="lazy" 
              src={imageURL}
              alt="digtalAge"
              className={`${imageMuiProp} w-100 h-100 image-container`}
            />
            <div className="overlay p-2"></div>
          </Grid>
        )}
        {dividerHeight && (
          <Grid
            item
            xs={12}
            padding={1}
            className="d-flex justify-content-center align-items-center"
          >
            <Box
              width={dividerWidth}
              height={dividerHeight}
              className="divider-color"
            />
          </Grid>
        )}
        {headingContent && (
          <Grid item padding={1} xs={12}>
            {isTooltip ? (
              <Tooltip title={headingContent} placement="bottom">
                <Typography
                  variant="h6"
                  color={hover ? "rgba(36, 171, 227, 1)" : " black"}
                  className="fw-700 text-center container-text"
                  sx={{ ...sxTitle }}
                >
                  {headingContent}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                variant="h6"
                color={hover ? "rgba(36, 171, 227, 1)" : " black"}
                className="fw-700 text-center container-text"
                sx={{ ...sxTitle }}
              >
                {headingContent}
              </Typography>
            )}
          </Grid>
        )}
        <Grid
          item
          padding={1}
          xs={12}
          className="fw-400"
        >
          <Typography
            variant="section2"
            className="txt-description fw-400 fs-12"
            sx={{
              ...sxDescription,
            }}
          >
            {descriptionContent}
          </Typography>
        </Grid>
        {showfooter && (
          <Grid item xs={12} padding={1}>
            <Link to={link} style={{ textDecoration: "none" }}>
              <Typography
                color="rgba(36, 171, 227, 1)"
                className="fs-12 fw-700 "
              >
                {cardText}
                <span className="ms-1">
                  <LaunchIcon fontSize={"13px"} />
                </span>
              </Typography>
            </Link>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default CaseStudycard;
