import { Box, Grid, Typography } from "@mui/material";
import * as React from "react";
const S3_URL = process.env.REACT_APP_S3_URL;
const icon =
  S3_URL+"/v3/assestsWebp/home/testimonialImage1.webp";
const quatos =
  S3_URL+"/v3/assestsWebp/home/testimonial-3-quote-icon.b53cb92b.png (1).webp";
export default function AboutAgencyCard({
  description = "",
  iconImage = icon,
  personName = "Christine Rose",
  personDesignation = "Customer",
}) {
  return (
    <>
      <Box
        padding={3}
        className="testimonials-content"
      >
        <Grid container bgcolor={"white"} padding={2} height={"100%"} className="overflow-y-auto">
          <Grid item xs={12} className="">
            <Typography className="fw-400 fs-14 txt-description">
              {description}
            </Typography>
          </Grid>
          <Grid item container xs={12} className="" alignItems={"center"}>
            <Grid item xs={3} className='d-flex  align-items-center' sx={{justifyContent:{sm:'start',md:"center"}}}>
              <Box
                width={"50px"}
                height={"50px"}
                padding={0.5}
                border={"1px solid #24ABE3"}
                borderRadius={"50%"}
                className='d-flex justify-content-center align-items-center'
              >
                <img loading="lazy" 
                  src={iconImage}
                  alt=""
                  className="h-100 w-100 rounded-circle"
                />
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box sx={{paddingLeft:{sm:1.5,md:0,lg:0}}}>
                <Typography className="fw-700 " sx={{fontSize:"70%"}}>{personName}</Typography>
                <Typography className="fw-700  chip-title-blue" sx={{fontSize:"60%"}}>
                  {personDesignation}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box>
                <img loading="lazy"  src={quatos} alt="" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
