import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

function CounterCard({
  icon = "",
  counter = "Developers",
  title = "Developers",
}) {
  let [count, setCount] = useState(false);
  const [hover, setHover] = useState(false);

  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCount(true);
          } else {
            setCount(false);
          }
        });
      },
      { threshold: 0.1 } 
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);


  return (
    <>
      <Box
        ref={cardRef}
        width={"100%"}
        bgcolor={"white"}
        padding={2}
        className="d-flex justify-content-center flex-column my-1 gradient-border Shadow-effects hover-bg-color"
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <Grid container item xs={12}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Box height={"50px"} width={"50px"}>
              {icon &&
                React.cloneElement(icon, {
                  svgcolor: hover ? "white" : undefined,
                })}
            </Box>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography className="fw-700 fs-24 text-center lh-sm chip-title-blue mt-3  count">
            {count && counter}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="fw-700 fs-14 text-center lh-sm  mt-2 title">
            {title}
          </Typography>
        </Grid>
      </Box>
    </>
  );
}

export default CounterCard;
