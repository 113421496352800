
import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import ContentSection from "../../molecules/ContentSection";
import IndustriesCard from "../../molecules/IndustriesCard";
import PageLayout from "../../organisms/PageLayout";
import ReusableBackground1 from "../../organisms/ReusableBackground1";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const S3_URL = process.env.REACT_APP_S3_URL;

const Banner = S3_URL + "/v3/assestsWebp/industries/banner.webp";
const industries1 = S3_URL + "/v3/assestsWebp/industries/industries1.webp";
const industries2 = S3_URL + "/v3/assestsWebp/industries/industries2.webp";
const industries3 = S3_URL + "/v3/assestsWebp/industries/industries3.webp";
const industries4 = S3_URL + "/v3/assestsWebp/industries/industries4.webp";
const industries5 = S3_URL + "/v3/assestsWebp/industries/industries5.webp";
const industries6 = S3_URL + "/v3/assestsWebp/industries/industries6.webp";
const industries7 = S3_URL + "/v3/assestsWebp/industries/industries7.webp";
const industries8 = S3_URL + "/v3/assestsWebp/industries/industries8.webp";
const industriesIcon1 = S3_URL + "/v3/assestsWebp/industries/bsfiIcon.webp";
const industriesIcon2 = S3_URL + "/v3/assestsWebp/industries/mnufacturingIcon.webp";
const industriesIcon3 = S3_URL + "/v3/assestsWebp/industries/telecomIcon.webp";
const industriesIcon4 = S3_URL + "/v3/assestsWebp/industries/healthcareIcon.webp";
const industriesIcon5 = S3_URL + "/v3/assestsWebp/industries/eCommerceIcon.webp";
const industriesIcon6 =
  S3_URL + "/v3/assestsWebp/industries/travelHospitalityIcon.webp";
const industriesIcon7 =
  S3_URL + "/v3/assestsWebp/industries/energyUtilitiesIcon.webp";
const industriesIcon8 = S3_URL + "/v3/assestsWebp/industries/educationIcon.webp";

const articles = [
  {
    title: "BSFI",
    description: (
      <>
        <span>
          <a
            href={process.env.REACT_APP_INSTAGRAM_URL}
            target="blank"
            className="content-links fw-600 inudtries-card-link-text"
          >
            {" "}
            Dyashin{" "}
          </a>
          is at the forefront of technological innovation in the BFSI industry,
          delivering cutting-edge solutions tailored to meet the unique
          challenges of Banking, <a href="https://dyashin.com/services" target="blank" className="content-links fw-600 inudtries-card-link-text">Financial Services  </a>, and Insurance. Our
          expertise empowers clients to navigate the evolving landscape with
          precision, enhancing efficiency, security, and customer experience in
          the dynamic BFSI sector.",
        </span>
      </>
    ),

    image: industries1,
    icon: industriesIcon1,
    id: "bfsi",
    link: "/case-study",
  },
  {
    title: "Manufacturing",
    description: (
      <>
        <span>
          Dyashin pioneers transformative solutions for the Manufacturing
          industry, integrating cutting-edge technologies to optimize processes 
          and enhance productivity. Our tailored software and IT services empower manufacturers to streamline
          operations, improve supply chain efficiency, and navigate the
          complexities of Industry 4.0. Elevate your manufacturing capabilities
          with <a
            href={process.env.REACT_APP_QUORA_URL}
            target="blank"
            className="content-links fw-600 inudtries-card-link-text"
          >
          Dyashin's{" "}
          </a> innovative solutions for a sustainable and
          digitally-driven future.{" "}
        </span>
      </>
    ),
    image: industries2,
    icon: industriesIcon2,
    id: "manufacturing",
    link: "/case-study",
  },
  {
    title: "Telecom",
    description: (
      <>
        <span>
          In the dynamic landscape of the Telecom industry, we emerges as a
          catalyst for innovation and efficiency. Our bespoke solutions leverage
          cutting-edge technologies to optimize network performance, enhance
          <a href={process.env.REACT_APP_QUORA_URL} target="blank" className="content-links fw-600 inudtries-card-link-text"> customer experiences </a>, and propel businesses forward. Trust us to
          navigate the complexities of the{" "}
          <a
            href={process.env.REACT_APP_X_URL}
            target="blank"
            className="content-links fw-600 inudtries-card-link-text"
          >
            {" "}
            Dyashin{" "}
          </a>
          Telecom sector, delivering tailored solutions that empower and
          transform.
        </span>
      </>
    ),
    image: industries3,
    icon: industriesIcon3,
    id: "telecom",
    link: "/case-study",
  },
  {
    title: "Healthcare",
    description: (
      <>
        <span>
          In the dynamic landscape of the healthcare industry, we pioneers
          transformative solutions. Our{" "}
          <a
            href={process.env.REACT_APP_PINTEREST_URL}
            target="blank"
            className="content-links fw-600 inudtries-card-link-text"
          >
            {" "}
            Dyashin{" "}
          </a>
          innovative technologies empower healthcare providers to enhance
          patient care, streamline operations, and embrace digital advancements.
          From robust <a href="https://dyashin.com/solutions" target="blank" className="content-links fw-600 inudtries-card-link-text"> software solutions </a> to cutting-edge applications, we are
          dedicated to revolutionizing healthcare delivery and driving
          efficiency in an ever-evolving sector{" "}
        </span>
      </>
    ),
    image: industries4,
    icon: industriesIcon4,
    id: "healthcare",
    link: "/case-study",
  },
  {
    title: "Retail & E-commerce",
    description: (
      <>
        <span>
          We pioneers innovative solutions for the Retail & <a href="https://dyashin.com/services" target="blank" className="content-links fw-600 inudtries-card-link-text "> E-Commerce industry </a>,
          delivering cutting-edge technologies to enhance customer experiences,
          streamline operations, and drive business growth. Our tailored
          software solutions empower businesses to stay ahead in this{" "} 
          <a
            href={process.env.REACT_APP_LINKEDIN_URL}
            target="blank"
            className="content-links fw-600 inudtries-card-link-text"
          >
            dynamic{" "}
          </a>
          landscape, offering seamless integration, robust analytics, and
          scalable platforms that transform the way retailers engage with their
          customers.
        </span>
      </>
    ),
    image: industries5,
    icon: industriesIcon5,
    id: "retail-and-ecommerce",
    link: "/case-study",
  },
  {
    title: "Travel & Hospitality",
    description: (
      <>
        <span>
          Elevate your Travel & Hospitality business with{" "}
          <a
            href={process.env.REACT_APP_YOUTUBE_URL}
            target="blank"
            className="content-links fw-600 inudtries-card-link-text"
          >
            {" "}
            Dyashin Technosoft's{" "}
          </a>{" "}
          innovative solutions. From seamless booking experiences and
          personalized customer interactions to advanced analytics for strategic
          insights, we empower you to deliver exceptional service. Our
          cutting-edge technology transforms the travel landscape, ensuring
          efficiency, engagement, and memorable experiences for your customers.
        </span>
      </>
    ),
    image: industries6,
    icon: industriesIcon6,
    id: "travel-and-hospitality",
    link: "/case-study",
  },
  {
    title: "Energy & Utilities",
    description: (
      <>
        <span>
          We transforms the Energy & Utilities industry with with <a href=" https://dyashin.com/solutions" target="blank" className="content-links fw-600 inudtries-card-link-text"> innovative solutions </a>. Our{" "}
          <a
            href={process.env.REACT_APP_FACEBOOK_URL}
            target="blank"
            className="content-links fw-600 inudtries-card-link-text"
          >
            {" "}
            cutting-edge{" "}
          </a>{" "}
          technologies enhance operational efficiency, optimize resource
          utilization, and ensure sustainable practices. From smart grid
          implementations to data analytics, we empower organizations to
          navigate the evolving landscape, fostering resilience and growth in
          the dynamic Energy & Utilities sector.
        </span>
      </>
    ),
    image: industries7,
    icon: industriesIcon7,
    id: "energy-and-utilities",
    link: "/case-study",
  },
  {
    title: "Education",
    description: (
      <>
        <span>
          We transforms the education industry with innovative solutions. From
          cutting-edge e-learning platforms to tailor-made software for academic
          institutions, we empower educators and learners alike. Our commitment
          to advancing education through technology ensures seamless
          integration, fostering an environment where knowledge flourishes.
          Elevate your educational experience with{" "} 
          <a
            href={process.env.REACT_APP_BLOGSPOT_URL}
            target="blank"
            className="content-links fw-600 inudtries-card-link-text"
          >
            {" "}
            Dyashin Technosoft{" "}
          </a>
          , driving the future of learning.
        </span>
      </>
    ),
    image: industries8,
    icon: industriesIcon8,
    id: "education",
    link: "/case-study",
  },
];

const IndustriesPage = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  const { section } = useParams();

  useEffect(() => {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        const yOffset = -60;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  });
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Dyashin : Industries</title>
        <meta
          property="og:title"
          content="Leading Industry Solutions by Dyashin - Shaping the Future of Business"
        />
        <meta
          name="description"
          content="Dyashin delivers industry-specific solutions that revolutionize business operations and drive technological innovation. Explore our industry-focused services."
        />
        <meta
          property="og:description"
          content="Dyashin delivers industry-specific solutions that revolutionize business operations and drive technological innovation. Explore our industry-focused services."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PageLayout backroundImg={Banner} pageTitle="Industries">
        <ReusableBackground1 displayImage={false}>
          <ContentSection
            badgeLabel={"Industries"}
            badgeTextColor="text-info"
            titleMuiProp="fs-40"
            sxChip={{
              backgrounColor: "#8DABE142",
              color: "#24ABE3",
            }}
            title={"Our Industries"}
            sxChild={{ textAlign: "start" }}
            sx={{ marginTop: 5 }}
            chipMuiProps="content-chip1"
          >
            {articles.map((article, index) => (
              <Grid item xs={12} sm={6} md={4} padding={1.2}>
                <Box id={article.id} height={"100%"}>
                  <Grid
                    container
                    className="h-100 inudtries-card cursor-pointer"
                    boxShadow={"0px 1.5px 20px #d1d0d6"}
                  
                  >
                    <Grid item xs={12} className=" ">
                      <IndustriesCard
                        description={article.description}
                        heading={article.title}
                        icon={article.icon}
                        imageURL={article.image}
                        key={index}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className=" fs-12 fw-700 chip-title-blue p-3 inudtries-card-footer-text"
                      alignContent={"end"}
                    >
                      <Link
                        to={article.link}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        Case study
                        <LaunchIcon
                          fontSize="12px"
                          sx={{ marginLeft: 1, color: "#24ABE3" }}
                          className="inudtries-card-footer-text"
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </ContentSection>
        </ReusableBackground1>
      </PageLayout>
    </>
  );
};

export default IndustriesPage;
