import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ChipComponent from "../../atoms/ChipComponent";
import { Link } from "react-router-dom";
import FarwarButton from "../../atoms/FarwardButton/farwarButton";
const S3_URL = process.env.REACT_APP_S3_URL;
const EDIFY_URL = process.env.REACT_APP_EDIFY_URL;

const jobbyLogo = S3_URL + "/v3/assestsWebp/products/jobbyLogo.webp";
const edifyLogo = S3_URL + "/v3/assestsWebp/products/edifyLogo.webp";
const pristineLogo = S3_URL + "/v3/assestsWebp/products/pristineLogo.webp";
const sigoLogo = S3_URL + "/v3/assestsWebp/products/sigoLogo.webp";
const jobbybackgroung = S3_URL + "/v3/assestsWebp/products/jobbybackgroung.webp";
const edifyBackGround = S3_URL + "/v3/assestsWebp/products/edifyBackGround.webp";
const pristineBackGround =
  S3_URL + "/v3/assestsWebp/products/pristineBackGround.webp";
const sigoBackground = S3_URL + "/v3/assestsWebp/products/sigoBackground.webp";

function Products() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  let ProductsData = [
    {
      chipLabel: "JOB PORTAL",
      description: <>Our <Link to={'/resources/blogs/ai-in-recruitment'} className="content-links fw-600">AI-enabled</Link> product revolutionizing the job-seeking landscape for both seekers and providers. Finding the perfect job is effortless with DSJobby, where top recruiters actively seek talent, creating seamless connections. It's not just a boon for job seekers; DSJobby empowers recruitment teams by tracking candidate progress. Our advanced AI technology ensures precise candidate-job matches, streamlining the hiring process. DSJobby is not just a platform; it's a dynamic solution bridging the gap between talent and opportunities. Experience the future of recruitment with DSJobby – where job searches are intelligent, connections are meaningful, and career paths are transformed.</>,
      proLogo: jobbyLogo,
      probackgroungimage: jobbybackgroung,
      id: "job-portal",
      isproduct: false,
      link: "",
    },
    {
      chipLabel: "LMS PLATFORM",
      description:(<>Our cutting-edge Learning Management System (LMS) product, is designed to meet the imperative of knowledge sharing. Beyond standard LMS features, <Link to={'/resources/blogs/empowering-education'} className="content-links fw-600">DSEdify</Link> incorporates coding challenges, enhancing practical learning. Its robust examination modules ensure comprehensive assessments. With an in-house content creation team and strategic partnerships with top-tier content creators worldwide, DSEdify hosts a diverse range of high-quality videos—both technical and non-technical. This holistic approach enriches the learning experience, making DSEdify an unparalleled platform for education and skill development. Empower your team with a dynamic LMS that goes beyond boundaries, crafted by <a href={process.env.REACT_APP_LINKEDIN_URL} target="blank" className="content-links fw-600">Dyashin Technosoft </a> for a seamless blend of innovation and knowledge dissemination.</>),
      proLogo: edifyLogo,
      probackgroungimage: edifyBackGround,
      id: "lms",
      isproduct: true,
      link: EDIFY_URL,
    },
    {
      chipLabel: "MICRO SUITE ERP",
      description: (<>An innovative HRMS product by <a href={process.env.REACT_APP_QUORA_URL} target="blank" className="content-links fw-600">Dyashin Technosoft</a>, is engineered to revolutionize resource management across diverse industries. Addressing the challenges of handling resources effectively, DSPristine combines versatility and precision. This comprehensive solution seamlessly runs payroll operations for multiple countries, ensuring compliance and efficiency. Automating a spectrum of tasks, DSPristine enhances operational agility, allowing businesses to focus on strategic growth. With its intuitive interface and robust features, DSPristine emerges as the go-to HRMS product, streamlining resource management, fostering compliance, and empowering organizations to navigate the complexities of workforce administration with ease. Elevate your HR processes with DSPristine – the epitome of efficiency in human resource management.</>),
      proLogo: pristineLogo,
      probackgroungimage: pristineBackGround,
      id: "erp",
      isproduct: false,
      link: "",
    },
    {
      chipLabel: "E-COMMERCE PLATFORM",
      description: (<> <a href={process.env.REACT_APP_INSTAGRAM_URL} target="blank" className="content-links fw-600">Dyashin Technosoft's</a> innovative B2B ecommerce solution revolutionizing procurement processes. Sigo empowers companies to effortlessly purchase products, featuring a seamless payment integration system for secure and efficient transactions. By eliminating middlemen, Sigo significantly reduces the likelihood of compliance risks associated with traditional procurement. This user-friendly platform streamlines the purchasing journey, enhancing operational efficiency for businesses of all sizes. From sourcing to payment, Sigo is designed to simplify the B2B ecommerce landscape, fostering transparency and trust. Elevate your procurement experience with Sigo, the cutting-edge solution crafted to redefine the way companies acquire goods, ensuring a smoother, compliant, and cost-effective procurement process.</>),
      proLogo: sigoLogo,
      probackgroungimage: sigoBackground,
      id: "ecommerce",
      isproduct: false,
      link: "",
    },
  ];

  return (
    <>
      <h1>{process.env.EDIFY_URL}</h1>
      {ProductsData.map((datas, idx) =>
        isSmallScreen ? (
          <Grid container key={idx} id={datas.id} className="mb-5">
            <Grid item xs={12} className="d-flex justify-content-center mb-5">
              <div className="products-image-box">
                <img loading="lazy"  src={datas.proLogo} alt={datas.chipLabel} width="100%" />
                <img loading="lazy" 
                  src={datas.probackgroungimage}
                  alt={datas.chipLabel}
                  width="100%"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <ChipComponent
                label={datas.chipLabel}
                sx={{ backgroundColor: "#8DABE142", color: "#24ABE3" }}
                chipMuiProps="content-chip1"
              />
              <Typography
                className="txt-description fs-14 py-3"
                align="justify"
              >
                {datas.description}
              </Typography>
              <Typography className="fs-14 fw-700 d-flex justify-content-start align-items-center">
                Know More{" "}
                <Link
                  to={datas.isproduct ? datas.link : "/products"}
                  className="d-flex justify-content-start align-items-center ms-4"
                  target={datas.isproduct ? "_blank" : "_self"}
                  rel={datas.isproduct ? "noopener noreferrer" : ""}
                >
                  <FarwarButton />
                </Link>
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container id={datas.id} key={idx} sx={{ marginTop: 5 }}>
            <Grid
              item
              container
              xs={12}
              sm={6}
              paddingTop={4}
              className="d-flex justify-content-center"
            >
              {idx % 2 === 0 ? (
                <Grid item xs={12}>
                  <ChipComponent
                    label={datas.chipLabel}
                    sx={{ backgroundColor: "#8DABE142", color: "#24ABE3" }}
                    chipMuiProps="content-chip1"
                  />
                  <Typography
                    className="txt-description fs-14 py-3"
                    align="justify"
                  >
                    {datas.description}
                  </Typography>
                  <Typography className="fs-14 fw-700 d-flex justify-content-start align-items-center">
                    Know More{" "}
                    <Link
                      to={datas.isproduct ? datas.link : "/products"}
                      className="d-flex justify-content-start align-items-center ms-4"
                      target={datas.isproduct ? "_blank" : "_self"}
                      rel={datas.isproduct ? "noopener noreferrer" : ""}
                    >
                      <FarwarButton />
                    </Link>
                  </Typography>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div className="products-image-box">
                    <img loading="lazy" 
                      src={datas.proLogo}
                      alt={datas.chipLabel}
                      width="100%"
                    />
                    <img loading="lazy" 
                      src={datas.probackgroungimage}
                      alt={datas.chipLabel}
                      width="100%"
                    />
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              className="d-flex justify-content-center align-items-center"
            >
              {idx % 2 === 0 ? (
                <Grid
                  item
                  xs={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div className="products-image-box">
                    <img loading="lazy" 
                      src={datas.proLogo}
                      alt={datas.chipLabel}
                      width="100%"
                    />
                    <img loading="lazy" 
                      src={datas.probackgroungimage}
                      alt={datas.chipLabel}
                      width="100%"
                    />
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} paddingY={4}>
                  <ChipComponent
                    label={datas.chipLabel}
                    sx={{ backgroundColor: "#8DABE142", color: "#24ABE3" }}
                    chipMuiProps="content-chip1"
                  />
                  <Typography
                    className="txt-description fs-14 py-3"
                    align="justify"
                  >
                    {datas.description}
                  </Typography>
                  <Typography className="fs-14 fw-700 d-flex justify-content-start align-items-center">
                    Know More
                    <Link
                      to={datas.isproduct ? datas.link : "/products"}
                      className="d-flex justify-content-start align-items-center ms-4"
                      target={datas.isproduct ? "_blank" : "_self"}
                      rel={datas.isproduct ? "noopener noreferrer" : ""}
                    >
                      <FarwarButton />
                    </Link>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )
      )}
    </>
  );
}

export default Products;
