import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import RoundedRectangleBox from "../../../atoms/RoundedRectangleBox";
import COLORS from "../../../../constants/colorConstants";

const { BLUE1 } = COLORS
function HomePageYoutube() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box
        position="relative"
        sx={{
          backgroundColor: BLUE1,
          width: "100%",
          marginBottom: { md: "25%", xs: "32%" },
        }}

      >
        <Grid container padding={"8%"}>
          &nbsp;
        </Grid>
        <Grid container sx={{ position: "absolute", top: "25%" }}>
          <Grid
            item
            xs={1}
            md={2}
            sx={{ paddingTop: "0%", position: "relative" }}
          >
            {!isSmallScreen && (
              <>
                <RoundedRectangleBox
                  animationClassName="triangle-animation"
                  height="90%"
                  width="80%"
                  top="5%"
                  left="-10%"
                  backgroundColor="#24ABE3"
                  sx={{ transform: "rotate(-45deg)" }}
                />
                <RoundedRectangleBox
                  animationClassName="triangle-animation"
                  height="50%"
                  width="50%"
                  top="-12%"
                  left="45%"
                  backgroundColor="black"
                  sx={{ transform: "rotate(-45deg)" }}
                />
              </>
            )}
          </Grid>

          <Grid
            item
            container
            xs={10}
            md={8}
            sx={{ zIndex: 50 }}
            className="justify-content-center "
          >
            <div className="video-container position-relative w-100 ">
              <iframe
                loading="lazy"
                src="https://www.youtube.com/embed/_f1lQz4K-Vw?si=hwT4IKoMW97apbQv?rel=0"
                title="YouTube video player"
                className="position-absolute w-100 youtube-video-height"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicyolicy="strict-origin-when-cross-origin"

              ></iframe>
            </div>
          </Grid>
          <Grid item xs={1} md={2}></Grid>
        </Grid>
      </Box>
    </>
  );
}
export default HomePageYoutube;
